import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
    Card, Divider, Header, Icon, Loader, Button,
    Segment, Modal, Dimmer, Image, Grid,
} from 'semantic-ui-react';

import {getPlaceOrderInProgress} from "../../views/Orders/reducer";
import {Link} from "react-router-dom";

import './styles.css';
import {getCountries, isCountriesFetching} from "../AddressCard/reducer";
import {
    fetchCounties,
} from "../AddressCard/actions";
import OrderCardHeader from "./OrderCardHeader";
import {receiveOrderData} from "../../views/Orders/actions";
import ModalPayment from "../ModalPayment/ModalPayment";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";
import {getAuthFetching} from "../UserLogin/reducer";

export default function MiniOrderCard(props) {

    const { order, circular, header, actionButtons, dispatch, token, userData, userLocation, mixpanel , isLoggedIn } = props;
    const { billing, id, status} =  order;

    const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));
    const getLoadingCountries = useSelector((state) => isCountriesFetching(state.address));
    const isFetchingUserData = useSelector((state) => getAuthFetching(state.userLogin));
    const countries = useSelector((state) => getCountries(state.address));
    const language = useSelector(getLanguage);
    const languageString = language[0] + language[1];

    const [paymentOpen, setPaymentOpen] = React.useState(false);

    const country = countries && countries.countries ? countries.countries[billing.country] ?? {} : {};
    const countryStates = country && country.states
        ? country.states.filter( item => String(item.code) === String(billing.state)) : [];

    useEffect(() => {
        if (typeof country.iso === 'undefined' && !getLoadingCountries) {
            console.warn('Mini order card has queried countries ...');
            dispatch(fetchCounties());
        }
        // защита от зависания страницы в лоадинге
        dispatch(receiveOrderData([]));
    }, []);

    const orderCountry = country.iso ? country.emoji + ' ' + country['country_' + languageString] : '';
    const orderState = countryStates.length ? countryStates[0]['name_' + languageString] : '';
    const needsPayment = status === 'pending' || status === 'on-hold';

    const OrderImage = () => (
        <Image src={userData.avatar ?? "https://react.semantic-ui.com/images/wireframe/square-image.png"}
               size="medium"
               circular={ order.file_image ? false : circular}
               className="order-image"
        />
    );

    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] : key;
    };

    const billing_suffix_lang = languageString === 'ru' ? 'uc' : languageString;

    // console.log('mini order card', isFetchingUserData, getLoadingCountries, getLoading)

    return (
        <div>
            <Card centered color="blue" raised className="card-order">
                <OrderCardHeader order={order} {...props}/>
                <p className={'order-header-name-services'}>{languageString === "ru" ? order.line_items.name : order.line_items.name_en}</p>
                <Card.Content className="card-order-content">
                    <Grid className='grid-order-first-second-col'>
                        <Grid.Column className="order-mini-card-header-first-col">
                            <OrderImage/>
                        </Grid.Column>
                        <Grid.Column className="order-mini-card-header-second-col">
                            <Card.Header as="h4" className="order-header-fio-col">
                                {billing['last_name_' + billing_suffix_lang] + ' ' + billing['first_name_' + billing_suffix_lang]}<br/>
                                {billing['company_' + billing_suffix_lang]}<br/>
                                <br/>
                                {billing['company_job_title_' + billing_suffix_lang]}<br/>
                                {billing['company_scope_' + billing_suffix_lang]}<br/>
                            </Card.Header>
                        </Grid.Column>
                    </Grid>
                    <div className="order-mini-card-country" size="small"
                         dangerouslySetInnerHTML={{__html: `${orderCountry}, ${orderState}`}}/>

                    <Segment className="order-mini-card-contacts">
                        <a className="order-mini-card-phone" href={'tel:' + billing.phone}>
                            <Icon name="call" style={{color:'#B10000', width: '13px',height:'13px', transform:'scaleX(-1)'}}/>
                            {billing.phone}
                        </a>
                        <a className="order-mini-card-mail" href={'mailto:' + billing.email}>
                            <Icon name="mail" style={{color:'#B10000', width: '13px',height:'13px'}}/>
                            {billing.email}
                        </a>
                    </Segment>
                    <div className="order-card-action-buttons">
                        {actionButtons
                            ?
                            <div>
                                <Button.Group className="mini-order-action-buttons" fluid>
                                    {needsPayment ?
                                        <>
                                            <Button
                                                className="mini-order-action-button-pay"
                                                onClick={() => setPaymentOpen(true)}
                                                compact
                                            >
                                                {t('payment')}
                                            </Button>
                                            <ModalPayment
                                                open={paymentOpen}
                                                headerIcon="payment"
                                                headerText={t("payWndHeader")}
                                                handleOpen={() => setPaymentOpen(true)}
                                                handleClose={() => setPaymentOpen(false)}
                                                order={order}
                                            />
                                        </>
                                        : null}
                                </Button.Group>
                            </div>
                            : <Link to={'/orders/' + id}>
                                <Button fluid color="orange">
                                    {t('details')}
                                </Button>
                            </Link>}
                    </div>
                </Card.Content>
            </Card>

        </div>
    );
}