import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import "./style.css";
import { getLanguage } from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import SadIcon from '../../icons/sad-icon.png';
import {getOrders} from "../Orders/reducer";
import {Dimmer, Header, Loader} from "semantic-ui-react";
import {addProduct, removeAllProductsFromCart} from "../Cart/actions";
import CheckoutSteps, {CHECKOUT_STEP_CONFIRM} from "../../components/CheckoutSteps";
import OrderCard from "../../components/OrderCard";
import {getProducts, getProductsFetching} from "../Products/reducer";
import {fetchProducts, PRODUCTS_PAGE_COUNT} from "../Products/actions";
import {fetchUserData} from "../../components/UserLogin/actions";
import {getAuthFetching} from "../../components/UserLogin/reducer";
import {removeCoupon} from "../../components/CouponCard/actions";
import {getDiscount} from "../../components/CouponCard/reducer";
import {toastr} from "react-redux-toastr";

const Ticket = (props) => {
    const { isLoggedIn, dispatch, userData, token, navigate, mixpanel} = props;

    const language = useSelector(getLanguage);
    const languageString = language[0] + language[1];

    // функция t в каждом компоненте, ее надо вынести в отдельный файл, например, App.js и импортировать
    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] : key;
    };

    // определяем заказы в редаксе (но их там может не быть) и в активном списке заказов у юзера
    const reduxOrders = useSelector( (state) => getOrders(state.orders));
    const userOrders = isLoggedIn ? userData.orders.active ?? [] : [];

    /*
    варианты обработки (создавать новый заказ / отобразить сушествующий)

    1. если юзер не залогинен, то сообщаем ему об этом,
    предлагаем ему или войти и найти свой билет или купить билет по новой
    (со временем логика может помеянться, ее надо обсудить с продактами)

    2. если юзер залогинен, и у него есть активные заказы, то берем его самый свежий заказ и отображаем,
    в нем уже есть все данные и искать его в редаксе не нужно

    3. если юзер залогинен, и у него нет активных заказов, то предлагаем купить билет по новой
    */
    const createNew = !isLoggedIn || !userOrders[0];
    const order = createNew ? {} : userOrders[0];

    // список продуктов нужен для получения билета, берем за основу с индексом 0 и его цену
    // нужно добавить проверку, вдруг в редаксе еще пусто, приложение запрашивает их при запуске, но вдруг не успеют придти с сервера
    const products = useSelector((state) => getProducts(state.products));
    const productsFetching = useSelector((state) => getProductsFetching(state.products));
    const isFetchingUserData = useSelector((state) => getAuthFetching(state.userLogin));
    const loaderText = t('loadInfo');
    const product = products[0] ?? {};
    const coupon = useSelector((state) => getDiscount(state.discount));
    const couponCode = coupon?.data?.coupon ?? ''

    useEffect(() => {
        window.scrollTo(0, 0);

        // console.log('useEffect Ticket')
        // перезапрашиваем пользовательские данные на предмет существования билетов
        if (token)
            dispatch(fetchUserData(token, languageString));

        dispatch(removeCoupon(couponCode));
        // проверяем продукт на наличие
        if (!products[0]) {
            dispatch(fetchProducts({
                page: 1,
                per_page: PRODUCTS_PAGE_COUNT,
                order: 'asc',
                orderby: 'id',
                lang: languageString,
            }));
        }

        if (mixpanel) {
            mixpanel.track('View. Ticket')
        }

    }, []);

    const handleBuyTicket = () => {

        if ( !navigator.onLine) {
            toastr.warning(languageString === "ru" ? "Вы не в сети. Пожалуйста, подключитесь к Интернету" : 'You are offline. Please connect to the Internet')
            return;
        }
        dispatch(removeAllProductsFromCart());
        dispatch(addProduct(
            product.id,
            product.name,
            product.price,
            product.images[0],
            product.virtual,
            null,
            null,
        ));

        if (isLoggedIn && navigator.onLine)
            navigate('/checkout-step-payment')
        else
            navigate('/checkout-step-shipping');

    }

    if (isFetchingUserData)
    return (
        <Dimmer active inverted>
            <Loader active>{t("loadInfo")}...</Loader>
        </Dimmer>
    );

    if (createNew) {
        return (
            <div className="no-ticket-page">
                <img src={SadIcon} alt="" />
                <div className="no-ticket-message">
                    {!isLoggedIn ? (
                        <span>{t('youDontHaveTicket')}<br/>{t('costTicket')}<br/>{t('pleaseLogInAndPay')}</span>
                    ) : (
                        <span>{t('youDontHaveTicket')}<br/>{t('costTicket')}<br/>{t('pleasePay')}</span>
                    )}
                </div>
                <div className="button-container" style={{marginTop: '20px'}}>
                    <button className="buy-ticket-button" onClick={handleBuyTicket}>
                        <span>{t('buyTicket')}</span>
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div>
            <CheckoutSteps step={CHECKOUT_STEP_CONFIRM} />
            <Header textAlign="center" className='checkout-step-confirm-header'>{t('yourUniqueCode')} - {order.id}</Header>
            <OrderCard
                order={order}
                {...props}
            />
            <span className="checkout-step-confirm-text-asiaexpo">{t('PresentYourCodeAtTheRegistration')}<br/>{t('ASIAEXPOToReceiveYourBadge')}</span>
        </div>
    );
};

export default Ticket;
