import React, {useEffect} from 'react';
import { Header } from 'semantic-ui-react';
import {useSelector} from 'react-redux';

import '../../components/ModalPayment/styles.css';
import CheckoutSteps, {CHECKOUT_STEP_CONFIRM} from '../../components/CheckoutSteps';

import {closeSearch} from "../../components/NavTopBar/actions";
import {getOrders, getPlaceOrderInProgress} from "../Orders/reducer";
import OrderCard from "../../components/OrderCard";
import ModalPayment from "../../components/ModalPayment/ModalPayment";
import {getPaymentMethod} from "../Checkout_2_StepPayment/reducer";
import {PAYMENT_OPTION_ONLINE} from "../Checkout_2_StepPayment/actions";
import {useParams} from "react-router";
import "./style.css"
import {receiveOrderData} from "../Orders/actions";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";

export default function CheckoutStepConfirm(props) {

    const {dispatch, userData, userLocation, mixpanel, isLoggedIn} = props;

    const matchParams = useParams();
    const orderKey = matchParams.orderKey;

    const orders = useSelector((state) => getOrders(state.orders));
    const placedOrder = orders.find((obj) => obj.order_key === orderKey);

    //console.log('placed orders', orders, matchParams, orderKey, placedOrder);

    if (!placedOrder)
        return ('Заказ не найден');

    const payment_option = placedOrder ? placedOrder.payment_method : '';

    const needsPayment = placedOrder && (placedOrder.status === 'pending' || placedOrder.status === 'on-hold') &&
        payment_option === PAYMENT_OPTION_ONLINE;
    const [paymentOpen, setPaymentOpen] = React.useState(needsPayment);

    useEffect(() => {
        if (mixpanel)
            mixpanel.track('Checkout Order Placed', { ...userLocation, ...userData, order_id: placedOrder.id, payment_option });
        window.scrollTo(0, 0);
        dispatch(receiveOrderData([]));
    }, []);

    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    return (
          <div>
              <CheckoutSteps step={CHECKOUT_STEP_CONFIRM} />
              <Header textAlign="center" className='checkout-step-confirm-header'>{t("yourUniqueCode")} - {placedOrder.id}</Header>
              <OrderCard
                  order={placedOrder}
                  {...props}
              />
              { needsPayment ?
              <ModalPayment
                  open = {paymentOpen}
                  headerIcon = "payment"
                  headerText = {t("payWndHeader")}
                  order = {placedOrder}
                  handleOpen = {() => setPaymentOpen(true)}
                  handleClose = {() => setPaymentOpen(false)}
                  {...props}
              /> : ''}
              <span className="checkout-step-confirm-text-asiaexpo">{t('PresentYourCodeAtTheRegistration')}<br/>{t('ASIAEXPOToReceiveYourBadge')}</span>
          </div>
    );
  }



