import React, { useEffect } from 'react';
import {useSelector} from 'react-redux';
import { Accordion } from 'semantic-ui-react';
import InfiniteView from '../../components/InfiniteView';
import './styles.css'

import {FAQ_PAGE_COUNT, fetchFAQ} from './actions';
import {getFAQFetching, getFAQ, getFAQHasMore} from './reducer';
import {setWindowScrollPosition} from "../../components/WindowDimensions";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";

export default function Products(props) {

    const {dispatch, userData, userLocation, mixpanel, routeAction, isPageReloaded} = props;

    const loadingFaq = useSelector((state) => getFAQFetching(state.faq));
    const faq = useSelector((state) => getFAQ(state.faq));
    const hasMoreFAQ = useSelector((state) => getFAQHasMore(state.faq));

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    useEffect(() => {
        if (routeAction === 'PUSH' || isPageReloaded) {
            readFAQ(1);
            setWindowScrollPosition();
        }
        if (mixpanel)
            mixpanel.track('FAQ List View', {...userLocation, ...userData});
    }, []);

    const loadFAQ = () => {
        if (hasMoreFAQ) {
            const nextPage = Math.round(faq.length / FAQ_PAGE_COUNT) + 1;
            readFAQ(nextPage);
        }
    };

    const readFAQ = (page) => {
        dispatch(fetchFAQ({
            page,
            per_page: FAQ_PAGE_COUNT,
            app: 'users',
            order: 'asc',
            orderby: 'faq_order',
            lang: languageString,
        }));
    };

    const accordionFAQ = () => (
        <Accordion
            className="style-accordion-FAQ"
            styled
            panels={faq.map((item, index) => ({
                key: index,
                title: languageString === "en" ? item.question_en : item.question,
                content: {content: (<p className="accordion-content"
                                       dangerouslySetInnerHTML={{__html: languageString === "en" ? item.short_answer_en.replace(/\n/g, '<br/>') : item.answer.replace(/\n/g, '<br/>')}}/>)
                }
            }))}
        />
    )

    return (
        <InfiniteView
            headerTitle={t("askedQuestions")}
            dataLength={faq.length}
            nextFetch={loadFAQ}
            hasMore={hasMoreFAQ}
            dataList={accordionFAQ()}
            mustAuth={false}
            hideEndMessage={true}
            // isLoading={loadingFaq}
            loaderText={t("loadInfo")}
            zeroText="Часто задаваемые вопросы отсутствуют"
        />
    );
}
