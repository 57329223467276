import React, {useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import './style.css';

import {FormField, Dimmer, Loader, Form, Button, Dropdown} from 'semantic-ui-react'
import { FioSuggestions, EmailSuggestions, PartySuggestions, AddressSuggestions } from 'react-dadata';
import InputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { DADATA_API_KEY } from '../../config/secrets';
import ModalDateSelection from '../../components/ModalDateSelection/ModalDateSelection';



import UserIcon from '../../icons/user-icon.png';
import { ReactComponent as EditIcon } from "../../icons/edit-icon.svg";

import { updateUserData } from "../../components/UserLogin/actions";
import { setFormChanged, resetFormChanged, setPopupClose } from "./actions";
import {
    ADDRESS_COUNTRY,
    ADDRESS_PHONE,
    ADDRESS_PHONE_COUNTRY, fetchCounties, setAddressFields,
} from '../../components/AddressCard/actions';
import {getAddressFields, getCountries, isCountriesFetching} from '../../components/AddressCard/reducer';

import PhoneCountryMask from '../../components/AddressCard/PhoneCountryMask';
import ModalPrompt from "../../components/ModalPrompt/index.js";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import {toastr} from "react-redux-toastr";


export default function EditProfile (props) {
    const {dispatch, navigate, token, mixpanel} = props;
    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };
    if (!token) {
        navigate('/');
        toastr.error(t('notAuthorized'));
    }

    const route = useSelector(state => state.formReducer.actionRoute);
    const isPopupOpen = useSelector(state => state.formReducer.isPopupOpen);


    const fields = useSelector((state) => getAddressFields(state.address));
    const isFetching = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));
    const arrayCountries = countries && countries.countries ? Object.values(countries.countries).map(country => ({
        'key': country.iso.toLowerCase(),
        'value': country.iso.toLowerCase(),
        'flag': country.iso.toLowerCase(),
        'text': languageString === "en" ? country.country_en : country.country_ru,
        'states': country.states
    })) : [];
    const formFields = [
        'first_name',
        'last_name',
        'email',
        'phone_country',
        'phone',
        'country',
        'state',
        'city',
        'company',
        'company_job_title',
        'gender',
        'doc_kind',
        'passport_seria',
        'passport_date',
        'fms_unit',
        'birth_address',
        'passport_birth',
    ];

    const userData = useSelector(state => state.userLogin.userData);
    const docKindsRu = Object.entries(userData.options.identity_doc_kinds.ru)
        .map(([value, text]) => ({text: text.charAt(0).toUpperCase() + text.slice(1), value}))
        .sort((a,b) => parseInt(a.value) - parseInt(b.value));
    const docKindsEn = Object.entries(userData.options.identity_doc_kinds.en)
        .map(([value, text]) => ({text: text.charAt(0).toUpperCase() + text.slice(1), value}))
        .sort((a,b) => parseInt(a.value) - parseInt(b.value));
    const gendersRu = Object.entries(userData.options.identity_genders.ru).map(([value, text]) => ({text: text.charAt(0).toUpperCase() + text.slice(1), value}));
    const gendersEn = Object.entries(userData.options.identity_genders.en).map(([value, text]) => ({text: text.charAt(0).toUpperCase() + text.slice(1), value}));

    const [formData, setFormData] = useState({
        avatar: userData.avatar,
        first_name: userData.billing.first_name,
        last_name: userData.billing.last_name,
        email: userData.billing.email,
        gender: userData.identity.gender,
        doc_kind: userData.identity.doc_kind,
        passport_seria: userData.identity.doc_number,
        passport_date: userData.identity.doc_issue_date,
        fms_unit: userData.identity.doc_issue_dept,
        birth_address: userData.identity.birth_place,
        passport_birth: userData.identity.birth_date,
        phone: userData.billing.phone,
        phone_country: userData.billing.phone_country,
        country: userData.billing.country,
        city: userData.billing.city,
        state: userData.billing.state,
        company: userData.billing.company,
        company_job_title: userData.billing.company_job_title,
        is_juridic: userData.billing.is_juridic,
        id: userData.id,
    });
    // console.log(formData)
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const [regionList, setRegionList] = useState([]);

    const [errors, setErrors] = useState({});
    // console.log(errors)
    const isFormValid = Object.values(errors).every(error => error === '') && isChecked;
    const [statusButton, setStatusButton] = useState(isFormValid);
    const [birthModal, setBirthModal] = useState(false);
    const [issueDate, setIssueDate] = useState(false);

    const inputAvatarRef = useRef(null);

    useEffect(() => {
        handleCheckCountry(null, {value: formData.country});
        // if (!countries || !countries.countries || !countries.countries[fields[ADDRESS_COUNTRY]]) {
        //     dispatch(fetchCounties());
        // }
        mountDirectField([
            {name: 'countryPhoneCode', value: formData.phone_country},
            {name: 'phone', value: formData.phone}
        ]);
        dispatch(resetFormChanged());
        dispatch(setPopupClose());
        validateFieds();
        if (mixpanel) {
            mixpanel.track('View. Edit profile')
        }

    }, []);

    useEffect(() => {
         setStatusButton(!isFormValid);
    }, [errors, isFormValid, isChecked]);

    //Блокировка перезагрузки страницы
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            dispatch(resetFormChanged());
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [formData, userData]);



    let listPhoneCountries = [];
    if (!isFetching && countries && countries.countries) {
        listPhoneCountries = Object.values(countries.countries).map((element, index) => (
            {
                key:  index,
                text: languageString === "en" ? element.country_en : element.country_ru,
                flag: _.lowerCase(element.iso),
                value: element.iso,
                className: "dropdown-item",
                phone_mask: element.phone_mask,
            }
        ));
    }

    const phoneMask = listPhoneCountries.find(item => item.value === formData.phone_country)?.phone_mask;

    const formatResult = (value, currentValue, suggestions) => {
        suggestions.value = suggestions.data.code;
        return suggestions.data.code + ' -  ' + suggestions.data.name;
    }

    const showSuggestions = (suggestions) => {
        $('#fms_unit').val(suggestions.data.name);
    }

    const clearSuggestions = () => {
        $('#fms_unit').val('');
    }


    // Проверка наличия изменений, вывод попапа
    const checkFormChanges = () => {
        const isAvatarChanged = formData['avatar'] !== userData['avatar'];
        const isFieldChanged = formFields.some(field => formData[field] !== userData.billing[field]);
        if (isFieldChanged || isAvatarChanged) {
            dispatch(setFormChanged());
        } else {
            dispatch(resetFormChanged());
        }
    }

    const validateFieds = () => {
        formFields.forEach(field => {
            validate(field, formData[field]);
        })
    }

    const changeRegion = (value) => {
        const searchCountry = arrayCountries.find(country => country.value === value.toLowerCase());
        const regions = searchCountry && searchCountry['states'] ? Object.values(searchCountry['states']).map((region) => ({text: languageString === "ru" ? region.name_ru : region.name_en, value: region.code.toString()})) : [];
        setRegionList(regions);
    }

    //Вывод поля с регионом в зависимости от страны и обновление стейта с данными
    const handleCheckCountry = (e, {value}) => {
        setFormData({
            ...formData,
            country: value
        });
        changeRegion(value);

        checkFormChanges();
    }

    const handleChangeGender = (e, {value}) => {
        validate( 'gender', value)
        setFormData({
            ...formData,
            gender: value
        });
        checkFormChanges();
    }

    const handleChangeDoc = (e, {value}) => {
        validate('doc_kind', value)
        setFormData({
            ...formData,
            doc_kind: value
        });
        checkFormChanges();
    }


    //Обновление полей с телефонной маской
    function updateField() {
        formData.phone_country = fields[ADDRESS_PHONE_COUNTRY];
        formData.phone = fields[ADDRESS_PHONE];
        dispatch(setAddressFields(fields));
        checkFormChanges();
    }

    //Заполнение полей с телефонной масокй при загрузке страницы
    const mountDirectField = (data) => {
        data.forEach((element) => {
            fields[element.name] = element.value;
        });
        updateField();
    }

    //Изменение полей при редактировании маски
    const setDirectField = (name, data) => {
        if (name === 'countryPhoneCode') {
            fields['phone'] = '';
        }
        fields[name] = data;
        updateField();
    }

    //Смена аватара
    const handleImageClick = () => {
        inputAvatarRef.current.click();
    }

    // Обработчик изменения изображения
    const handleImageChange = (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onload = () => {
            const base64Url = reader.result;

            setFormData({
                ...formData,
                avatar: base64Url
            });

            checkFormChanges();
        };
        reader.readAsDataURL(file);
    }

    //Обновление стейта с регионом
    const handleCityChange = (e, { value }) => {
        validate('state', value);
        setFormData({
            ...formData,
            city: value,
            state: value,
        });
        checkFormChanges();
    }

    const handleFirstNameChange = (value) => {
        validate('first_name', value.value);
        setFormData({
            ...formData,
            first_name: value.value,
        });
        checkFormChanges();
    }

    const handleLastNameChange = (value) => {
        validate('last_name', value.value);
        setFormData({
            ...formData,
            last_name: value.value,
        });
        checkFormChanges();
    }

    const handleEmailChange = (value) => {
        validate('email', value.value);
        setFormData({
            ...formData,
            email: value.value,
        });
        checkFormChanges();
    }

    const handleAddressChange = (value) => {
        validate('address', value.value);
        setFormData({
            ...formData,
            address: value.value,
        });
        checkFormChanges();
    }

    const handleBirthChange = (value) => {
        validate('birth_address', value.value);
        setFormData({
            ...formData,
            birth_address: value.value,
        });
        checkFormChanges();
    }

    const handleCompanyChange = (value) => {
        validate('company', value.value);
        setFormData({
            ...formData,
            company: value.value,
        });
        checkFormChanges();
    }

    // Обновление стейта с даннымми
    const handleChange = (e) => {
        const { name, value } = e.target;
        validate(name, value);
        setFormData({
            ...formData,
            [name]:  value,
        });
        // console.log(formData)
        checkFormChanges();
    }

    const handleChangePassportDate = (date) =>{
        setFormData({
            ...formData,
            passport_date: date
        })
        checkFormChanges();
    }
    const handleChangeBirthDate = (date) =>{
        setFormData({
            ...formData,
            passport_birth: date
        })
        //console.log(date);
        checkFormChanges();
    }

    //Проверка пчоты
    const isValidEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    //Валидация полей в зависимости от поля
    const validate = (fieldName, value) => {
       let fieldErrors = {};
       if (fieldName === 'first_name') {
           if (!value) {
               fieldErrors = { [fieldName]: t("validName")};
           } else {
               fieldErrors = { [fieldName]: ''};
           }
       }
       if (fieldName === 'last_name') {
           if (!value) {
               fieldErrors = { [fieldName]: t("validSurname")};
           } else {
               fieldErrors = { [fieldName]: ''};
           }
       }
       if (fieldName === 'email') {
           if (!isValidEmail(value) && value !== '') {
               fieldErrors = { [fieldName]: t("validMailTwo")};
           } else {
               fieldErrors = { [fieldName]: ''};
           }
       }
       if (fieldName === 'gender') {
           if(!value) {
               fieldErrors = { [fieldName]: t("validGender")};
           } else {
               fieldErrors = { [fieldName]: ''}
           }
       }
       // if (fieldName === 'doc_kind') {
       //     if(!value) {
       //         fieldErrors = { [fieldName]: t("validDoc")};
       //     } else {
       //         fieldErrors = { [fieldName]: ''}
       //     }
       // }
       // if (fieldName === 'passport_seria') {
       //     if(!value) {
       //         fieldErrors = { [fieldName]: t("validSeria")}
       //     } else {
       //         fieldErrors = { [fieldName]: ''}
       //     }
       // }
       //  if (fieldName === 'passport_date') {
       //      if(!value) {
       //          fieldErrors = { [fieldName]: t("validDate")}
       //      } else {
       //          fieldErrors = { [fieldName]: ''}
       //      }
       //  }
       //  if (fieldName === 'fms_unit') {
       //      if(!value) {
       //          fieldErrors = { [fieldName]: t("validFms")}
       //      } else {
       //          fieldErrors = { [fieldName]: ''}
       //      }
       //  }
       if (fieldName === 'phone') {
           if (!value) {
               fieldErrors = { [fieldName]: t("validNumber")};
           } else {
               fieldErrors = { [fieldName]: ''};
           }
       }
       if (fieldName === 'country') {
           if (!value) {
               fieldErrors = { [fieldName]: t("validCountry")};
           } else {
               fieldErrors = { [fieldName]: ''};
           }
       }
       if (fieldName === 'state') {
           if (!value) {
               fieldErrors = { [fieldName]: t("validRegion")};
           } else {
               fieldErrors = { [fieldName]: ''};
           }
       }

       // if (fieldName === 'company') {
       //     if (!value) {
       //         fieldErrors = { [fieldName]: t("validCompany")};
       //     } else {
       //         fieldErrors = { [fieldName]: ''};
       //     }
       // }
       // if (fieldName === 'job_title') {
       //     if (!value) {
       //         fieldErrors = { [fieldName]: t("validJobTitle")};
       //     } else {
       //         fieldErrors = { [fieldName]: ''};
       //     }
       // }
       setErrors((prevErrors) => ({ ...prevErrors, ...fieldErrors }));
    }

    //Отправка данных
    const submitUserData = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const query = JSON.stringify({
            avatar: formData.avatar,
            lang: languageString,
            billing: {
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email,
                phone: formData.phone,
                phone_country: formData.phone_country,
                country: formData.country.toUpperCase(),
                company: formData.company,
                company_job_title: formData.company_job_title,
                city: formData.city,
                state: formData.state,
                is_juridic: formData.company_job_title && company ? true : false,
            },
            identity: {
                gender: formData.gender,
                doc_kind: formData.doc_kind,
                doc_number: formData.passport_seria,
                doc_issue_dept: formData.fms_unit,
                doc_issue_date: formData.passport_date,
                birth_place: formData.birth_address,
                birth_date: formData.passport_birth
            }
        })
        dispatch(updateUserData(props.token, formData.id, query, false, languageString))
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
        dispatch(resetFormChanged());
        if (mixpanel) {
            mixpanel.track('Action. Save profile')
        }
    }


    return (
        <div className="edit-view">
            <Form className="edit-form" onSubmit={submitUserData}>
                <FormField>
                    <div onClick={handleImageClick}>
                        {!formData.avatar === '' ? (
                            <div className="edit-icon-container">
                                <img src={UserIcon} className="edit-icon-1" alt="Аватар"/>
                                <EditIcon className="edit-icon-2"/>
                            </div>
                        ) : (
                            <div className="edit-icon-container">
                                <img src={formData.avatar} alt="Аватар" className="edit-avatar"/>
                                <EditIcon className="edit-icon-2"/>
                            </div>
                        )}
                        <input type="file" name="avatar"  accept="image/png, image/gif, image/jpeg" ref={inputAvatarRef} onChange={handleImageChange} style={{display: 'none'}}/>
                    </div>
                </FormField>
                <FormField className='edit-field'>
                    <div className='edit-container'>
                        <FioSuggestions
                            token={DADATA_API_KEY}
                            inputProps={{
                                className: 'edit-input',
                                name: 'first_name',
                                id: 'first_name',
                                placeholder: t("name"),
                                required: true,
                                onChange: handleChange
                            }}
                            defaultQuery={formData.first_name}
                            count='4'
                            onChange={handleFirstNameChange}
                        />
                        {errors.first_name && <span className='edit-error'>{errors.first_name}</span>}
                    </div>
                    <div className='edit-container' id="input-lastname">
                        <FioSuggestions
                            token={DADATA_API_KEY}
                            inputProps={{
                                className: 'edit-input',
                                name: 'last_name',
                                id: 'last_name',
                                placeholder: t("surname"),
                                required: true,
                                onChange: handleChange
                            }}
                            defaultQuery={formData.last_name}
                            count='4'
                            filterParts={['surname']}
                            onChange={handleLastNameChange}
                        />
                        {errors.last_name && <span className='edit-error'>{errors.last_name}</span>}
                    </div>
                    <div className='edit-container'>
                        <PhoneCountryMask
                            extraClass="edit-input-phone edit-input"
                            name="phone"
                            countries={listPhoneCountries}
                            country={formData.phone_country}
                            loading={isFetching}
                            phone={formData.phone}
                            phoneMask={phoneMask}
                            handleChangeField={setDirectField}
                            nameCountry={ADDRESS_PHONE_COUNTRY}
                            namePhone={ADDRESS_PHONE}
                            fluid={true}
                            required
                        />
                        {errors.phone && <span className='edit-error'>{errors.phone}</span>}
                    </div>
                    <div className='edit-container'>
                        <EmailSuggestions
                            token={DADATA_API_KEY}
                            inputProps={{
                                className: 'edit-input',
                                name: 'email',
                                id: 'email',
                                placeholder: t("mail"),
                                required: true,
                                onChange: handleChange
                            }}
                            defaultQuery={formData.email}
                            count='4'
                            onChange={handleEmailChange}
                        />
                        {errors.email && <span className='edit-error'>{errors.email}</span>}
                    </div>
                </FormField>
                <FormField className='edit-field'>
                    <label className='edit-label'>{t("passportData")}</label>
                    <div className='edit-container'>
                        <Dropdown
                            fluid
                            search
                            scrolling
                            selection
                            id='edit-input'
                            className='edit-input'
                            name='gender'
                            placeholder={t("gender")}
                            options={languageString === 'ru' ? gendersRu : gendersEn}
                            value={formData.gender}
                            onChange={handleChangeGender}
                            required
                        />
                        {errors.gender && <span className='edit-error'>{errors.gender}</span>}
                    </div>
                    <div className='edit-container'>
                        <Dropdown
                            fluid
                            search
                            scrolling
                            selection
                            className='edit-input doc_kind'
                            name='doc_kind'
                            placeholder={t('docKind')}
                            options={languageString === 'ru' ? docKindsRu : docKindsEn}
                            onChange={handleChangeDoc}
                            value={formData.doc_kind}

                            id='edit-input'
                            required
                        />
                        {errors.doc_kind && <span className='edit-error'>{errors.doc_kind}</span>}
                    </div>
                    <div className='edit-container'>
                       <InputMask
                            mask={languageString === 'en' ? '9999 9999' : ''}
                            maskChar=''
                            className='edit-input'
                            name='passport_seria'
                            value={formData.passport_seria}
                            onChange={handleChange}
                            type='text'
                            inputMode="numeric"
                            placeholder={t("serNum")}
                        />
                        {errors.passport_seria && <span className='edit-error'>{errors.passport_seria}</span>}
                    </div>
                    {/*<div className='edit-container'>*/}
                    {/*    <InputMask*/}
                    {/*        mask='999-999'*/}
                    {/*        maskChar=''*/}
                    {/*        type='text'*/}
                    {/*        inputMode="numeric"*/}
                    {/*        className='edit-input'*/}
                    {/*        name='passport_division_code'*/}
                    {/*        id='passport_division_code'*/}
                    {/*        placeholder='Код подразделения'*/}
                    {/*        onChange={handleChange}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className='edit-container'>
                           <input value={formData.passport_date} onClick={() => setIssueDate(true)} placeholder={t('dateIssue')} className="edit-input"/>
                            <ModalDateSelection
                                open={issueDate}
                                handleClose={() => setIssueDate(false)}
                                handleOpen={() => setIssueDate(true)}
                                selectedDate={formData.passport_date}
                                callback={(date) => handleChangePassportDate(date)}
                                setMinDate={new Date(1950, 1, 1)}
                                setMaxDate={new Date(2025, 1, 1)}
                                header={t('dateIssue')}
                                locale={languageString === 'ru' ? 'ru-RU' : 'en-US'}
                            />
                        {errors.passport_date && <span className='edit-error'>{errors.passport_date}</span>}
                    </div>
                    {/*<div className='edit-container'>*/}
                    {/*   <AddressSuggestions*/}
                    {/*        token={tokenDaData}*/}
                    {/*        inputProps={{*/}
                    {/*            className: 'edit-input',*/}
                    {/*            name: 'passport_address',*/}
                    {/*            id: 'passport_address',*/}
                    {/*            placeholder: t("regAddress"),*/}
                    {/*            onChange: handleChange*/}
                    {/*        }}*/}

                    {/*        count='4'*/}
                    {/*        onChange={handleAddressChange}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className='edit-container'>
                        <input
                            className='edit-input'
                            id='fms_unit'
                            name='fms_unit'
                            placeholder={t("issuedWhom")}
                            type='text'
                            onChange={handleChange}
                            value={formData.fms_unit}
                        />
                        {errors.fms_unit && <span className='edit-error'>{errors.fms_unit}</span>}
                    </div>
                    <div className='edit-container'>
                        <AddressSuggestions
                            token={DADATA_API_KEY}
                            inputProps={{
                                className: 'edit-input',
                                name: 'birth_address',
                                id: 'birth_address',
                                placeholder: t("placeBirth"),
                                defaultValue: formData.birth_address,
                                onChange: handleChange
                            }}
                            count='4'
                            defaultQuery={formData.birth_address}
                            onChange={handleBirthChange}
                            filterFromBound='city'
                            filterToBound='city'
                        />
                        {errors.birth_address && <span className='edit-error'>{errors.birth_address}</span>}
                    </div>
                    <div className='edit-container'>

                            <input value={formData.passport_birth} placeholder={t('dateBirth')} onClick={() => setBirthModal(true)}
                                   className="edit-input"/>
                            <ModalDateSelection
                                open={birthModal}
                                handleClose={() => setBirthModal(false)}
                                handleOpen={() => setBirthModal(true)}
                                selectedDate={formData.passport_birth}
                                callback={(date) => handleChangeBirthDate(date)}
                                setMinDate={new Date(1900, 1, 1)}
                                setMaxDate={new Date(2025, 1, 1)}
                                header={t('dateBirth')}
                                locale={languageString === 'ru' ? 'ru-RU' : 'en-US'}
                            />
                        {errors.passport_birth && <span className='edit-error'>{errors.passport_birth}</span>}
                    </div>
                </FormField>
                <FormField className='edit-field'>
                    <label className='edit-label'>{t("country")}</label>
                    <div className='edit-container'>
                        <Dropdown
                            fluid
                            search
                            scrolling
                            selection
                            className='edit-input'
                            name='country'
                            placeholder={t("country")}
                            type='text'
                            id='edit-input'
                            value={formData.country.toLowerCase()}
                            options={arrayCountries}
                            onChange={handleCheckCountry}

                        />
                        {errors.country && <span className='edit-error'>{errors.country}</span>}
                    </div>
                    <div className='edit-container'>
                        <Dropdown
                            fluid
                            search
                            scrolling
                            selection
                            className='edit-input'
                            name='state'
                            placeholder={t("region")}
                            options={regionList}
                            value={formData.state}
                            id='edit-input'
                            onChange={handleCityChange}
                            required
                        />
                        {errors.state && <span className='edit-error'>{errors.state}</span>}
                    </div>

                </FormField>
                <FormField className='edit-field'>
                    <label className='edit-label'>{t("org")}</label>
                    <div className='edit-container'>
                        <PartySuggestions
                            token={DADATA_API_KEY}
                            inputProps={{
                                className: 'edit-input',
                                name: 'company',
                                id: 'company',
                                placeholder: t("company"),
                                onChange: handleChange
                            }}
                            count='4'
                            defaultQuery={formData.company}
                            onChange={handleCompanyChange}
                        />
                        {errors.company && <span className='edit-error'>{errors.company}</span>}
                    </div>
                    <div className='edit-container'>
                        <input
                            className='edit-input'
                            name='company_job_title'
                            placeholder={t("jobTitle")}
                            value={formData.company_job_title}
                            onChange={handleChange}
                        />
                        {errors.company_job_title && <span className='edit-error'>{errors.company_job_title}</span>}
                    </div>
                </FormField>
                <FormField className='edit-field'>
                    <div className='edit-container edit-checkbox-container'>
                        <input className='edit-checkbox' name='checkbox' type='checkbox' onClick={() => setIsChecked(!isChecked)} checked={isChecked}/>
                        <p className='edit-checkbox-text'>{t("policy")} <a style={{cursor: 'pointer'}} onClick={() => navigate('/policy')}>{t("details")}</a></p>
                    </div>
                </FormField>
                <Button type='submit' className='edit-submit' disabled={statusButton}>{t("save")}</Button>
            </Form>
            <ModalPrompt
               open={isPopupOpen}
               headerText={t("exit")}
               messageText={t("exitWithoutSave")}
               handleYes={() => {
                   dispatch(setPopupClose());
                   dispatch(resetFormChanged());
                   navigate(route);
               }}
               handleNo={() => {
                   dispatch(setPopupClose())
               }}
               handleClose={() => {
                   dispatch(setPopupClose());
               }}
            />
            <Dimmer active={isLoading} inverted>
                <Loader active={isLoading}>{t("saveData")}...</Loader>
            </Dimmer>
        </div>
    )
}
