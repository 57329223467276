import React, {useState} from 'react'
import {Button, Header, Modal, ModalActions} from 'semantic-ui-react'
import Calendar from 'react-calendar';
import './styles.css';
import {isUndefined} from "lodash";
import {useSelector} from "react-redux";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";

const formatDateToString = (onDate) => {
    function join(date, options, separator) {
        function format(option) {
            let formatter = new Intl.DateTimeFormat('en', option);
            return formatter.format(date);
        }
        return options.map(format).join(separator);
    }

    let options = [{day: '2-digit'}, {month: '2-digit'}, {year: 'numeric'}];
    return join(onDate, options, '.');
}
export default function ModalDateSelection (props) {
    const {open, handleOpen, handleClose, callback, setMinDate, setMaxDate, header, selectedDate} = props;
    const [stateDate, setStateDate] = useState(selectedDate);
    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const handleSave = () => {
        const date = formatDateToString(stateDate);
        callback(date);
        handleClose();
    };

return(
    <Modal
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        className="modal-date-selection"
    >
        <Header content={header}/>
        <Modal.Content>
            <div>
                <Calendar maxDate={setMaxDate ?? new Date(9999, 11,31)}
                          minDate={setMinDate ?? new Date()}
                          locale={languageString === 'ru' ? 'ru-RU' : 'en-EN'}
                          onClickDay={(value, event)=> setStateDate(value)}/>
            </div>
        </Modal.Content>
        <ModalActions>
            <Button onClick={handleClose}>{languageString === 'ru' ? 'Отмена' : 'Cancel'}</Button>
            <Button className='modal-calendar-btn-positive' style={{backgroundImage: 'linear-gradient(to right, #D81524,#871C1C)'}} disabled={isUndefined(stateDate)} positive onClick={handleSave}>{languageString === 'ru' ? 'Сохранить' : 'Save'}</Button>
        </ModalActions>
    </Modal>
)
}
