import React from 'react';
import './style.css';
import ImageGallery from "react-image-gallery";
import {Header, Modal} from 'semantic-ui-react';
import { useState, useEffect, useRef } from 'react';
import {useWindowDimensions} from "../WindowDimensions";

const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
    };
};

const ModalImageGallery = (props) => {
    const { open, handleOpen, handleClose, images, index, showAsGallery } = props;

    const [headerText, setHeaderText] = useState('');

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const resizeObserverRef = useRef(null);

    useEffect(() => {
        const debouncedHandleResize = debounce(() => {
            if (resizeObserverRef.current) {
                const rect = resizeObserverRef.current.getContentRect();
                setDimensions({ width: rect.width, height: rect.height });
            }
        }, 20);

        const observer = new ResizeObserver(debouncedHandleResize);
        if (open) {
            observer.observe(document.querySelector('.image-gallery-modal-window'));
        } else {
            observer.disconnect();
        }

        return () => observer.disconnect();
    }, [open]);

    useEffect(() => {
        setHeaderText(images[index].header);
    }, [])

    const {height} = useWindowDimensions();

    const onSlide = (slideIndex) => {
        setHeaderText(images[slideIndex].header);
    }

    return(
        <Modal
            closeIcon
            dimmer
            centered
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            className="image-gallery-modal-window"
        >
            <Modal.Content className="image-gallery-content">
                <ImageGallery
                    items={ showAsGallery ? images : [images[index]]}
                    startIndex={ showAsGallery ? index : 0}
                    onSlide={(slideIndex) => {onSlide(slideIndex)}}
                    showPlayButton={showAsGallery}
                    showNav={showAsGallery}
                    showThumbnails={showAsGallery}
                    showFullscreenButton={showAsGallery}
                />
            </Modal.Content>
        </Modal>
        )
}
export default ModalImageGallery;