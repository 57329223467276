import React, {useEffect, useState} from 'react';
import './style.css';
import {toastr} from "react-redux-toastr";

import CalendarIcon from "../../icons/calendar-icon.png";
import SadIcon from "../../icons/sad-icon.png";

import {useSelector} from "react-redux";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import {getReqExp, getFetchingReq, getHasMoreReq} from "./reducer";
import {fetchMeetings, updateMeetingStatus, CARDS_PER_PAGE} from "./actions";
import { ReactComponent as Vector } from '../../icons/vector.svg';
import { ReactComponent as VectorIcon} from "../../icons/vector-icon-expo.svg";
import {setWindowScrollPosition} from "../../components/WindowDimensions";
import InfiniteView from "../../components/InfiniteView";
import MeetingComponent from "../../components/MeetingComponent/MeetingComponent";
import {Checkbox} from "semantic-ui-react";


export default function AsiaMeet(props) {
    const { token, navigate, dispatch, routeAction, isPageReloaded, mixpanel } = props;
    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };
    if (!token) {
        navigate('/');
        toastr.error(t('notAuthorized'));
    }
    const [isPlanedMeeting, setIsPlanedMeeting] = useState(true);

    const meets = useSelector((state) => getReqExp(state.meets));
    const isFetching = useSelector((state) => getFetchingReq(state.meets));
    const hasMore = useSelector((state) => getHasMoreReq(state.meets));
    const [open, setOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        if ( routeAction === 'PUSH' || isPageReloaded ) {
            setWindowScrollPosition();
        }
        if (!token) {
            navigate('/');
            toastr.error(t('notAuthorized'));
        }

        if (mixpanel) {
            mixpanel.track('View. AsiaMeet')
        }

    }, [])

    useEffect(() => {
        if(isPlanedMeeting) {
            readPlanned(1);
        } else {
            readRequests(1);
        }
    }, [isPlanedMeeting]);

    useEffect(() => {
        readPlanned(1);
    }, [isChecked]);

    const readRequests = (page) => {
        const params = {
            page,
            per_page: CARDS_PER_PAGE,
            order: 'asc',
            orderby: 'on_date',
            event: 'ASIAEXPO 2024',
            lang: languageString,
            statuses: ['directed'],
        }
        dispatch(fetchMeetings(params, token, languageString));
    }
    const readPlanned = (page) => {
        const params = {
            page,
            per_page: CARDS_PER_PAGE,
            order: 'asc',
            orderby: ['on_date', 'on_time'],
            event: 'ASIAEXPO 2024',
            lang: languageString,
            statuses: isChecked ? ['accepted', 'canceled', 'rejected', 'completed', 'deleted'] : ['accepted'],
        }
        dispatch(fetchMeetings(params, token, languageString));
    }

    const loadMoreReq = () => readRequests(Math.round(meets.length / CARDS_PER_PAGE) + 1);
    const loadMorePlanned = () => readPlanned(Math.round(meets.length / CARDS_PER_PAGE) + 1);

    const MeetsList = (props) => {
        const { meets } = props;
        return (
            <div className="asiameet-content">
                {meets.map((meet) => {
                    return isPlanedMeeting ? plannedMeeting(meet) : requestMeeting(meet)
                })}
            </div>
        )
    }
    const requestMeeting = (request) => {
        return (
            <MeetingComponent
                token={token}
                icon={CalendarIcon}
                cardId={request.id}
                cardDate={request.on_date}
                cardTime={request.on_time}
                cardActions={request.actions}
                cardStatus={{
                    ru: request.meeting_status_name,
                    en: request.meeting_status_name_en
                }}
                updateFunc={readRequests}
                content={
                    <div>
                        <div className='asiameet-card-text'>{request.name}</div>
                        <div className="asiameet-card-text"><span
                            style={{fontWeight: 'bold'}}>{t('placeholderPlaceText')}:</span> {request.placement}
                        </div>
                        <div className="asiameet-card-text">
                            <span style={{fontWeight: 'bold'}}>{t('company')}: </span>
                            {languageString === "ru" ?
                                request.respondent_exponent_name
                                :
                                request.respondent_exponent_name_en}</div>
                        <div className="asiameet-card-text">
                            <span style={{fontWeight: 'bold'}}>{t('initiator')}: </span>
                            {languageString === "ru" ?
                                request.initiator_user_name
                                :
                                request.initiator_user_name_en}
                        </div>
                        <div className="asiameet-card-text">
                            <span style={{fontWeight: 'bold'}}>{t('responder')}: </span>
                            {languageString === "ru" ?
                                request.respondent_user_name
                                :
                                request.respondent_user_name_en}
                        </div>
                    </div>
                }
            />
        )
    }

    const plannedMeeting = (meet) => {
        return (
            <MeetingComponent
                token={token}
                icon={CalendarIcon}
                cardId={meet.id}
                cardDate={meet.on_date}
                cardTime={meet.on_time}
                cardStatus={{
                    ru: meet.meeting_status_name,
                    en: meet.meeting_status_name_en
                }}
                cardActions={meet.actions}
                updateFunc={readPlanned}
                content={
                    <div>
                        <div className='asiameet-card-text'>{meet.name}</div>
                        <div className="asiameet-card-text"><span
                            style={{fontWeight: 'bold'}}>{t("placeholderPlaceText")}:</span> {meet.placement}
                        </div>
                        <div className="asiameet-card-text">
                            <span style={{fontWeight: 'bold'}}>{t('company')}: </span>
                            {languageString === "ru" ?
                                meet.respondent_exponent_name
                                :
                                meet.respondent_exponent_name_en}</div>
                        <div className="asiameet-card-text">
                            <span style={{fontWeight: 'bold'}}>{t('initiator')}: </span>
                            {languageString === "ru" ?
                                meet.initiator_user_name
                                :
                                meet.initiator_user_name_en}
                        </div>
                        <div className="asiameet-card-text">
                            <span style={{fontWeight: 'bold'}}>{t('responder')}: </span>
                            {languageString === "ru" ?
                                meet.respondent_user_name
                                :
                                meet.respondent_user_name_en}
                        </div>
                    </div>
                }
            />
        )
    }


    return (
        <div className="asiameet-view">
            <div className="asiameet-selector">
                <div
                    onClick={() => setIsPlanedMeeting(true)}
                    className={"asiameet-selector-item" + (isPlanedMeeting ? " selector-active" : "")}
                >
                    {t("planned")}
                </div>
                <div
                    onClick={() => setIsPlanedMeeting(false)}
                    className={"asiameet-selector-item" + (!isPlanedMeeting ? " selector-active" : "")}
                >
                    {t("requests")}
                </div>
            </div>
            <div className="asiameet-faq-container">
                <div className="asiameet-faq" onClick={() => setOpen(!open)}>{t("faqAsiameet")}
                    {!open ? <Vector className="asiameet-vector" alt="Vector_expo_card"/> :
                        <VectorIcon className="asiameet-vector" alt="Vector_expo_card"/>}
                </div>
                {open &&
                    <div className="asiameet-faq-content">
                        <p className="asiameet-faq-text">{t("faqAsiameetContent")}</p>
                        <button className="asiameet-faq-btn" onClick={() =>navigate('/exponents')}>{t("exhibitors")}</button>
                    </div>
                }
            </div>
            {isPlanedMeeting &&<div className="asiameet-checkbox-container">
                <p className="asiameet-checkbox-text">{t('checkboxAsiameet')}</p>
                <Checkbox toggle stlye={{backhroundColor: 'red'}} className='red-checkbox' onClick={() => setIsChecked(!isChecked)}></Checkbox>
            </div>}
            <InfiniteView
                dataLength={meets.length}
                nextFetch={isPlanedMeeting ? loadMorePlanned : loadMoreReq}
                hasMore={hasMore}
                className="asiameet-infinite"
                isLoading={isFetching}
                dataList={navigator.onLine ? (
                    <MeetsList
                        meets={meets}
                        {...props}
                    />
                ) : (
                    <div className='view-bookmarks'>
                        <img src={SadIcon} alt={""}/>
                        <span className='text-bookmark'>{isPlanedMeeting ? t("noPlanned") : t("noRequests")}</span>
                    </div>
                )}
                hideEndMessage={true}
                mustAuth={false}
                loaderText={languageString === 'ru' ? 'Загрузка...' : 'Loading...'}
                zeroText={(
                    <div className='view-bookmarks'>
                        <img src={SadIcon} alt={""}/>
                        <span className='text-bookmark'>{isPlanedMeeting ? t("noPlanned") : t("noRequests")}</span>
                    </div>
                )}
            />


        </div>
    )
}
