import React, {useEffect, useRef, useState} from 'react';
import {Stage, Layer, Image as KonvaImage, Label, Tag, Text as KonvaText, Line, Circle} from 'react-konva';
import useImage from 'use-image';
import {fetchExponents} from "../Exponents/action";
import './exposition.css'
import {useSelector} from "react-redux";
import {getLanguage} from "../../../components/LanguageModal/reducer";
import {Dimmer, Image, Loader, Modal} from "semantic-ui-react";
import {getExponents, getExponentsFetching} from "../Exponents/reducer";
import translations from "../../../components/LanguageModal/translations";
import { ReactComponent as Arrow } from '../../../icons/arrow-icon.svg'
import {getWindowDimensions, isOnline} from "../../../components/WindowDimensions";
import {useParams} from "react-router";
import Konva from "konva";
import {isMobile} from "react-device-detect";


const scaleBy = 1.03;

function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

function getCenter(p1, p2) {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
    };
}

function isTouchEnabled() {
    return ( 'ontouchstart' in window ) ||
        ( navigator.maxTouchPoints > 0 ) ||
        ( navigator.msMaxTouchPoints > 0 );
}

const Exposition = (props) => {

    const { navigate, dispatch, token, mixpanel } = props;
    const [image] = useImage('https://cms.asiaexpo.space/wp-content/uploads/2024/08/карта.svg');
    const [blueSquare] = useImage('https://cms.asiaexpo.space/wp-content/uploads/2024/08/Голубой-квадрат.svg');
    const [redSquareHor] = useImage('https://cms.asiaexpo.space/wp-content/uploads/2024/08/Красный-квадрат.svg');
    const [redSquareVer] = useImage('https://cms.asiaexpo.space/wp-content/uploads/2024/08/Красный-квадрат_2.svg');
    const [yellowSquareBig] = useImage('https://cms.asiaexpo.space/wp-content/uploads/2024/08/Желтый-квадрат-большой.svg');
    const [yellowSquare] = useImage('https://cms.asiaexpo.space/wp-content/uploads/2024/08/Ресурс-1.svg');
    const [greenSquare] = useImage('https://cms.asiaexpo.space/wp-content/uploads/2024/08/Зеленый-квадрат.svg');

    useEffect(() => {
        if (!isOnline(true, languageString)) {
            navigate('/')
        }
        if (mixpanel) {
            mixpanel.track('View. Exposition')
        }
    }, []);

    const { exponentId } = useParams()

    const isFetching = useSelector((state) => getExponentsFetching(state.exponents));

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];

    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const [highlightedShapes, setHighlightedShapes] = useState([]);
    const min_scale_text_label = 2.2;
    const min_scale_tag_label = 2.5;

    const [scale, setScale] = useState(1);
    const [offsetX, setOffsetX] = useState( -110);
    const [offsetY, setOffsetY] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const [blueWidth, setBlueWidth] = useState(0);
    const [blueHeight, setBlueHeight] = useState(0);
    const [redOneWidth, setRedOneWidth] = useState(0);
    const [redOneHeight, setRedOneHeight] = useState(0);
    const [redTwoWidth, setRedTwoWidth] = useState(0);
    const [redTwoHeight, setRedTwoHeight] = useState(0);
    const [yellowBigWidth, setYellowBigWidth] = useState(0);
    const [yellowBigHeight, setYellowBigHeight] = useState(0);
    const [yellowWidth, setYellowWidth] = useState(0);
    const [yellowHeight, setYellowHeight] = useState(0);
    const [greenWidth, setGreenWidth] = useState(0);
    const [greenHeight, setGreenHeight] = useState(0);

    const [currentExponentId, setCurrentExponentId] = useState(null);
    const [currentZoomLevel, setCurrentZoomLevel] = useState(1);
    // const [anim, setAnim] = useState(null);

    // useEffect(() => {
    //     if (exponentId) {
    //         const shapeToAnimate = shapes.find(shape => shape.id === exponentId);
    //         if (shapeToAnimate) {
    //             const period = 2000;
    //             // Убедитесь, что у вас есть доступ к элементу для анимации
    //             const blueHex = shapeToAnimate; // здесь добавьте свой элемент, который вы хотите анимировать
    //
    //             const animation = new Konva.Animation((frame) => {
    //                 const scale = Math.sin((frame.time * 2 * Math.PI) / period) + 0.001;
    //                 blueHex.scale({ x: scale, y: scale });
    //                 blueHex.getLayer().batchDraw(); // Не забывайте перерисовывать слой
    //             }, stageRef.current);
    //
    //             animation.start();
    //             setAnim(animation);
    //         }
    //     }
    //
    //     // Очистка анимации при размонтировании или новом элементе
    //     return () => {
    //         if (anim) {
    //             anim.stop();
    //             setAnim(null);
    //         }
    //     };
    // }, [exponentId, shapes]);


    useEffect(() => {
        if (exponentId) {
            const shapeToHighlight = shapes.find(shape => shape.id === exponentId);
            if (shapeToHighlight) {
                setHighlightedShapes(prev => [...prev, exponentId]);
            }
        }
    }, [exponentId]);

    useEffect(() => {
        if (image && blueSquare && redSquareHor && redSquareVer && yellowSquareBig && yellowSquare && greenSquare) {
            setImageWidth(image.width);
            setImageHeight(image.height);
            setBlueWidth(blueSquare.width);
            setBlueHeight(blueSquare.height);
            setRedOneWidth(redSquareHor.width);
            setRedOneHeight(redSquareHor.height);
            setRedTwoWidth(redSquareVer.width);
            setRedTwoHeight(redSquareVer.height);
            setYellowBigWidth(yellowSquareBig.width);
            setYellowBigHeight(yellowSquareBig.height);
            setYellowWidth(yellowSquare.width);
            setYellowHeight(yellowSquare.height);
            setGreenWidth(greenSquare.width);
            setGreenHeight(greenSquare.height);
            // console.log('useEffect update image')
        }
    }, [image]);


    // без квадратов
    // https://cms.asiaexpo.space/wp-content/uploads/2024/08/Frame-13.png

    // c
    // https://cms.asiaexpo.space/wp-content/uploads/2024/08/Frame-12.png

    /* image dimensions 1074 765 */


    const shapes = [
        {
            id: "195095",
            shape:
                {
                    x: 195.2,
                    y: 198,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11,
                    height: 11,
                    closed: true,
                    rotation: 5
                },
            text: {
                label: "C101",
                rotation: 0,
                //min_scale: 1.2,
                x: 174.5,
                y: 202,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "AWTECH" : "AWTECH",
                x: 199,
                y: 200,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 7,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.8,
                width: 15
                //min_scale: 2,
            }
        },
        {
            id: "197591",
            shape:
                {
                    x: 213,
                    y: 196,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11.4,
                    height: 11.4,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C102",
                rotation: 0,
                //min_scale: 1.2,
                x: 192.8,
                y: 200,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "КОПЫТРЕМСЕРВИС" : "KRS",
                x: 220,
                y: 200,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 7,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.8,
                width: 20
                //min_scale: 2,
            }
        },
        {
            id: "195222",
            shape:
                {
                    x: 229,
                    y: 198,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11.4,
                    height: 11.4,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C103",
                rotation: 0,
                //min_scale: 1.2,
                x: 208.8,
                y: 202,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "САТ ГРУПП" : "SAT GROUP",
                x: 235,
                y: 200,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.8,
                width: 20
                //min_scale: 2,
            }
        },
        {
            id: "198111",
            shape:
                {
                    x: 254.3,
                    y: 201,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11.4,
                    height: 11.4,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C104",
                rotation: 0,
                //min_scale: 1.2,
                x: 234,
                y: 205,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "АПИЦЕННА" : "APICENNA",
                x: 260,
                y: 203,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.8,
                width: 20
                //min_scale: 2,
            }
        },
        {
            id: "195081",
            shape:
                {
                    x: 266.9,
                    y: 203.7,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11.4,
                    height: 11.4,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C105",
                rotation: 0,
                //min_scale: 1.2,
                x: 247,
                y: 207,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "ФИДТЕХ" : "FEEDTECH",
                x: 273,
                y: 207,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.8,
                width: 20
                //min_scale: 2,
            }
        },
        {
            id: "195110",
            shape:
                {
                    x: 284.5,
                    y: 206.9,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11.4,
                    height: 11.4,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C106",
                rotation: 0,
                //min_scale: 1.2,
                x: 264.5,
                y: 210,
                size: 2.3,
            },
            tag: {
                name: languageString === 'ru' ? "ZINPRO" : "ZINPRO",
                x: 293,
                y: 209,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.8,
                width: 17
                //min_scale: 2,
            }
        },
        {
            id: "196261",
            shape:
                {
                    x: 303,
                    y: 210,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11.4,
                    height: 11.4,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C107",
                rotation: 0,
                //min_scale: 1.2,
                x: 283.5,
                y: 213,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "ПРОМОЛОКО-СОФТ" : "PROMOLOKO-SOFTWARE",
                x: 310,
                y: 213,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.6,
                width: 20
                //min_scale: 2,
            }
        },
        {
            id: "195157",
            shape:
                {
                    x: 317,
                    y: 213,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11.4,
                    height: 11.4,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C108",
                rotation: 0,
                //min_scale: 1.2,
                x: 297.5,
                y: 216,
                size: 2.3,
            },
            tag: {
                name: languageString === 'ru' ? "ЕВРОАГРОТЕК" : "EUROAGROTEK",
                x: 325,
                y: 215.4,
                rotation: 0,
                pointerDirection: "left",
                pointerHeight: 2.5,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.6,
                width: 20
                //min_scale: 2,
            }
        },
        {
            id: "198123",
            shape:
                {
                    x: 229.3    ,
                    y: 209.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11.6,
                    height: 11,
                    closed: true,
                    rotation: 5
                },
            text: {
                label: "А118",
                rotation: 0,
                //min_scale: 1.2,
                x: 209,
                y: 213,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "РУСПЛЕНКА" : "RUSPLENKA",
                x: 233,
                y: 211,
                rotation: 0,
                pointerDirection: "right",
                pointerHeight: 3,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.8,
                width: 20
                //min_scale: 2,
            }
        },
        {
            id: "195238",
            shape:
                {
                    x: 241.3,
                    y: 212,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11.6,
                    height: 11,
                    closed: true,
                    rotation: 5
                },
            text: {
                label: "А117",
                rotation: 0,
                //min_scale: 1.2,
                x: 221,
                y: 216,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "УЛЫБИНО" : "ULIBINO",
                x: 246,
                y: 214,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.7,
                width: 15
                //min_scale: 2,
            }
        },
        {
            id: "195107",
            shape:
                {
                    x: 254.3,
                    y: 214.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11.6,
                    height: 11,
                    closed: true,
                    rotation: 5
                },
            text: {
                label: "А116",
                rotation: 0,
                //min_scale: 1.2,
                x: 233.5,
                y: 219,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "UNORMAK" : "UNORMAK",
                x: 259,
                y: 217,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.7,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195200",
            shape:
                {
                    x: 267.3,
                    y: 217,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11.6,
                    height: 11,
                    closed: true,
                    rotation: 5
                },
            text: {
                label: "А115",
                rotation: 0,
                //min_scale: 1.2,
                x: 246.5,
                y: 221.3,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "ПОЛИПЛЕКС" : "POLIPLEX",
                x: 273,
                y: 219,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 1.5,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195122",
            shape:
                {
                    x: 280,
                    y: 219.4,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11.6,
                    height: 11,
                    closed: true,
                    rotation: 5
                },
            text: {
                label: "А114",
                rotation: 0,
                //min_scale: 1.2,
                x: 259.5,
                y: 223.3,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "АГРОНИКА" : "AGRONICA",
                x: 287,
                y: 221.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 1.5,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195139",
            shape:
                {
                    x: 292,
                    y: 221.8,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11.6,
                    height: 11,
                    closed: true,
                    rotation: 5
                },
            text: {
                label: "А113",
                rotation: 0,
                //min_scale: 1.2,
                x: 271.5,
                y: 225.3,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "ВЕКТОР-БЕСТ" : "VECTOR-BEST",
                x: 300,
                y: 224.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 1.5,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195244",
            shape:
                {
                    x: 306,
                    y: 224.8,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'blue',
                    width: 11.6,
                    height: 11,
                    closed: true,
                    rotation: 5
                },
            text: {
                label: "А112",
                rotation: 0,
                //min_scale: 1.2,
                x: 285.5,
                y: 228.3,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "ФЕРМЕНТ" : "FERMENT",
                x: 315,
                y: 227.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "198081",
            shape:
                {
                    x: 315.2,
                    y: 236.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red1',
                    width: 18.3,
                    height: 11,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "А111",
                rotation: 0,
                //min_scale: 1.2,
                x: 297.5,
                y: 239.6,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "ASIAEXPO" : "ASIAEXPO",
                x: 329,
                y: 240.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 5,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195241",
            shape:
                {
                    x: 309.3,
                    y: 246.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red1',
                    width: 18.3,
                    height: 11,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "А110/1",
                rotation: 0,
                //min_scale: 1.2,
                x: 292.5,
                y: 249.6,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "ФГБУ ВНИИЗЖ" : "FGBI VNIIZH",
                x: 324,
                y: 250.5,
                rotation: 0,
                pointerDirection: "left",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "197598",
            shape:
                {
                    x: 301.3,
                    y: 254,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red1',
                    width: 18.3,
                    height: 11,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "А110",
                rotation: 0,
                //min_scale: 1.2,
                x: 284.5,
                y: 257.6,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "КУБАНСКИЙ НАУЧНЫЙ ФОНД" : "KUBAN SCIENCE FOUNDATION",
                x: 317,
                y: 260.5,
                rotation: 0,
                pointerDirection: "left",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.2,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195175",
            shape:
                {
                    x: 292.3,
                    y: 262.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red1',
                    width: 18.3,
                    height: 11,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "А109",
                rotation: 0,
                //min_scale: 1.2,
                x: 275.5,
                y: 265.6,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "М-КОМПЛЕКС" : "M-COMPLEX",
                x: 308,
                y: 268.5,
                rotation: 0,
                pointerDirection: "left",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "198176",
            shape:
                {
                    x: 283.3,
                    y: 271.8,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red1',
                    width: 18.3,
                    height: 11,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "А108",
                rotation: 0,
                //min_scale: 1.2,
                x: 266.5,
                y: 275,
                size: 2.5,
            },
            tag: {
                name: languageString === 'ru' ? "РУСВЕТ" : "RUSVET",
                x: 300,
                y: 276.5,
                rotation: 0,
                pointerDirection: "left",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195206",
            shape:
                {
                    x: 360.3,
                    y: 245.8,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red2',
                    width: 15.7,
                    height: 15,
                    closed: true,
                    rotation: -5
                },
            text: {
                label: "B106",
                rotation: 0,
                //min_scale: 1.2,
                x: 343.7,
                y: 250,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "ПРОТЕКТФИД" : "PROTECT&FEED",
                x: 370,
                y: 246.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195104",
            shape:
                {
                    x: 343.8,
                    y: 257.8,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red2',
                    width: 15.7,
                    height: 14,
                    closed: true,
                    rotation: -5
                },
            text: {
                label: "B105",
                rotation: 0,
                //min_scale: 1.2,
                x: 326.3,
                y: 263,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "PAK TAVUK GIDA SAN. VE TIC A.Ş" : "PAK TAVUK GIDA SAN. VE TIC A.Ş",
                x: 355,
                y: 258.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195184",
            shape:
                {
                    x: 329.8,
                    y: 270.3,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red2',
                    width: 15.7,
                    height: 14,
                    closed: true,
                    rotation: -5
                },
            text: {
                label: "B104",
                rotation: 0,
                //min_scale: 1.2,
                x: 312,
                y: 276,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "НИТА-ФАРМ" : "NITA FARM",
                x: 338,
                y: 271.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195181",
            shape:
                {
                    x: 319.4,
                    y: 283.4,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red2',
                    width: 15.7,
                    height: 14,
                    closed: true,
                    rotation: -5
                },
            text: {
                label: "B103",
                rotation: 0,
                //min_scale: 1.2,
                x: 302.3,
                y: 288,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "БАШИНКОМ" : "BASHINKOM",
                x: 326,
                y: 286.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 8,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195142",
            shape:
                {
                    x: 311.4,
                    y: 296.4,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red2',
                    width: 15.7,
                    height: 14,
                    closed: true,
                    rotation: -5
                },
            text: {
                label: "B102",
                rotation: 0,
                //min_scale: 1.2,
                x: 293.6,
                y: 302,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "ВЕТПРОМ" : "VETPROM",
                x: 320,
                y: 298.5,
                rotation: 0,
                pointerDirection: "right",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195160",
            shape:
                {
                    x: 304.2,
                    y: 315.3,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red2',
                    width: 16,
                    height: 15.5,
                    closed: true,
                    rotation: -30
                },
            text: {
                label: "B101",
                rotation: 0,
                //min_scale: 1.2,
                x: 289,
                y: 315,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "КОРПОРАЦИЯ СКЭСС" : "SCESS CORPORATION",
                x: 312,
                y: 312,
                rotation: 0,
                pointerDirection: "right",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195166",
            shape:
                {
                    x: 253,
                    y: 297.3,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red2',
                    width: 16,
                    height: 15.5,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "A105",
                rotation: 0,
                //min_scale: 1.2,
                x: 235,
                y: 304,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "МЕГАМИКС" : "MEGAMIX",
                x: 264,
                y: 302,
                rotation: 0,
                pointerDirection: "left",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195209",
            shape:
                {
                    x: 245.7,
                    y: 311.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red2',
                    width: 16,
                    height: 14,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "A104",
                rotation: 0,
                //min_scale: 1.2,
                x: 228,
                y: 317,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "ГЕОДОР" : "GEODOR",
                x: 260,
                y: 315,
                rotation: 0,
                pointerDirection: "left",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "197583",
            shape:
                {
                    x: 237.7,
                    y: 324.3,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red2',
                    width: 16,
                    height: 14,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "A103",
                rotation: 0,
                //min_scale: 1.2,
                x: 220,
                y: 330,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "ВОРТЕКС" : "VORTEX",
                x: 250,
                y: 330,
                rotation: 0,
                pointerDirection: "left",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "197703",
            shape:
                {
                    x: 229.7,
                    y: 337.3,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red2',
                    width: 16,
                    height: 14,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "A102",
                rotation: 0,
                //min_scale: 1.2,
                x: 212.5,
                y: 342,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "SAUDI ELECTRONIC UNIVERSITY RESEARCH & STUDIES INSTITUTE" : "SAUDI ELECTRONIC UNIVERSITY RESEARCH & STUDIES INSTITUTE",
                x: 242,
                y: 342,
                rotation: 0,
                pointerDirection: "left",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.1,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195172",
            shape:
                {
                    x: 221,
                    y: 350,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'red2',
                    width: 16,
                    height: 14,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "A101",
                rotation: 0,
                //min_scale: 1.2,
                x: 203.5,
                y: 355,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "МИРАТОРГ" : "MIRATORG",
                x: 234,
                y: 355,
                rotation: 0,
                pointerDirection: "left",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.1,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195144",
            shape:
                {
                    x: 255,
                    y: 277.7,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'yellowbig',
                    width: 19,
                    height: 12,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "A107",
                rotation: 0,
                //min_scale: 1.2,
                x: 238,
                y: 282,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "ВИК" : "VIC",
                x: 265,
                y: 280,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 4,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.4,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195196",
            shape:
                {
                    x: 244,
                    y: 275.7,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'yellow',
                    width: 13,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "A106/1",
                rotation: 0,
                //min_scale: 1.2,
                x: 225,
                y: 278,
                size: 2.1,
            },
            tag: {
                name: languageString === 'ru' ? "ПЕНЗАЦАВТОМАШ" : "PENZASPETSAUTOMASH",
                x: 252,
                y: 277,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 4.3,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.2,
                width: 14
                //min_scale: 2,
            }
        },
        {
            id: "197683",
            shape:
                {
                    x: 231,
                    y: 272.7,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'yellow',
                    width: 13,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "D111",
                rotation: 0,
                //min_scale: 1.2,
                x: 212,
                y: 275,
                size: 2.1,
            },
            tag: {
                name: languageString === 'ru' ? "ОБЕЗВРЕЖИВАНИЕ ОТХОДОВ" : "WASTE DISPOSAL LLC",
                x: 238,
                y: 274,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 4.3,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.1,
                width: 14
                //min_scale: 2,
            }
        },
        {
            id: "195187",
            shape:
                {
                    x: 218.8,
                    y: 270.7,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'yellow',
                    width: 13,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "D112",
                rotation: 0,
                //min_scale: 1.2,
                x: 200,
                y: 273,
                size: 2.1,
            },
            tag: {
                name: languageString === 'ru' ? "АЭРОМЕХ" : "AEROMECH",
                x: 226,
                y: 272,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 7,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.1,
                width: 14
                //min_scale: 2,
            }
        },
        {
            id: "198182",
            shape:
                {
                    x: 206,
                    y: 267.7,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'yellow',
                    width: 13,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "D113",
                rotation: 0,
                //min_scale: 1.2,
                x: 187,
                y: 270,
                size: 2.1,
            },
            tag: {
                name: languageString === 'ru' ? "BIOSINT" : "BIOSINT",
                x: 214.5,
                y: 269.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 8.5,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.1,
                width: 14
                //min_scale: 2,
            }
        },
        {
            id: "197706",
            shape:
                {
                    x: 194,
                    y: 265.7,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'yellow',
                    width: 13,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "D114",
                rotation: 0,
                //min_scale: 1.2,
                x: 175,
                y: 268,
                size: 2.1,
            },
            tag: {
                name: languageString === 'ru' ? "HONG KONG YUANFENG INTERNATIONAL TRADING CO., LTD" : "HONG KONG YUANFENG INTERNATIONAL TRADING CO., LTD",
                x: 199,
                y: 266.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 1.2,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1,
                width: 21
                //min_scale: 2,
            }
        },
        {
            id: "197710",
            shape:
                {
                    x: 186,
                    y: 281.2,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'yellow',
                    width: 13,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "D115",
                rotation: 0,
                //min_scale: 1.2,
                x: 167,
                y: 284,
                size: 2.1,
            },
            tag: {
                name: languageString === 'ru' ? "GASSIN PIERRE PVT LTD" : "GASSIN PIERRE PVT LTD",
                x: 192,
                y: 282.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3.4,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.1,
                width: 15
                //min_scale: 2,
            }
        },
        {
            id: "195581",
            shape:
                {
                    x: 198.6,
                    y: 283.4,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'yellow',
                    width: 13,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "D104",
                rotation: 0,
                //min_scale: 1.2,
                x: 179.4,
                y: 286,
                size: 2.1,
            },
            tag: {
                name: languageString === 'ru' ? "BIOCHEM PHARMA" : "BIOCHEM PHARMA",
                x: 205,
                y: 284.9,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2.5,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.2,
                width: 14
                //min_scale: 2,
            }
        },
        {
            id: "198273",
            shape:
                {
                    x: 211,
                    y: 286,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'yellow',
                    width: 13,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "D103",
                rotation: 0,
                //min_scale: 1.2,
                x: 191.7,
                y: 289,
                size: 2.1,
            },
            tag: {
                name: languageString === 'ru' ? "AL-QAWAFEL IND. AGR. CO." : "AL-QAWAFEL IND. AGR. CO.",
                x: 218,
                y: 287.4,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1,
                width: 16
                //min_scale: 2,
            }
        },
        {
            id: "197587",
            shape:
                {
                    x: 223.5,
                    y: 288,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'yellow',
                    width: 13,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "D102",
                rotation: 0,
                //min_scale: 1.2,
                x: 204,
                y: 291,
                size: 2.1,
            },
            tag: {
                name: languageString === 'ru' ? "КОНЦЕПТ" : "CONCEPT",
                x: 232,
                y: 289.4,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.3,
                width: 10
                //min_scale: 2,
            }
        },
        {
            id: "195130",
            shape:
                {
                    x: 235.5,
                    y: 290,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'yellow',
                    width: 13,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "D101",
                rotation: 0,
                //min_scale: 1.2,
                x: 216,
                y: 292.5,
                size: 2.1,
            },
            tag: {
                name: languageString === 'ru' ? "АЛЬПАТРИЯ" : "ALPATRIA",
                x: 243,
                y: 291.4,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.3,
                width: 11
                //min_scale: 2,
            }
        },
        {
            id: "195101",
            shape:
                {
                    x: 246.5,
                    y: 293,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'yellow',
                    width: 13,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "А106",
                rotation: 0,
                //min_scale: 1.2,
                x: 227.3,
                y: 295.5,
                size: 2.1,
            },
            tag: {
                name: languageString === 'ru' ? "LALLEMAND" : "LALLEMAND",
                x: 255,
                y: 294.4,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.3,
                width: 11
                //min_scale: 2,
            }
        },
        {
            id: "195530",
            shape:
                {
                    x: 379.6,
                    y: 241.8,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12.4,
                    height: 10,
                    closed: true,
                    rotation: 5
                },
            text: {
                label: "B107",
                rotation: 0,
                //min_scale: 1.2,
                x: 359.7,
                y: 246,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "ТИМОФЕЕВ +)" : "TIMOFEEV + LLC) ",
                x: 384,
                y: 243.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "197689",
            shape:
                {
                    x: 398.3,
                    y: 239,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12,
                    height: 10,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "B108",
                rotation: 0,
                //min_scale: 1.2,
                x: 378.3,
                y: 243,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "НОВЫЙ ВЕК АГРОТЕХНОЛОГИЙ" : "NEO-AGRISERVIS",
                x: 401,
                y: 246.5,
                rotation: 0,
                pointerDirection: "up",
                pointerHeight: 4,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.2,
                width: 14
                //min_scale: 2,
            }
        },
        {
            id: "195212",
            shape:
                {
                    x: 411.3,
                    y: 240,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "B109",
                rotation: 0,
                //min_scale: 1.2,
                x: 391.3,
                y: 243,
                size: 2.3,
            },
            tag: {
                name: languageString === 'ru' ? "РЕММАШ" : "REMMASH",
                x: 414,
                y: 246.5,
                rotation: 0,
                pointerDirection: "up",
                pointerHeight: 3,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.2,
                width: 11
                //min_scale: 2,
            }
        },
        {
            id: "197614",
            shape:
                {
                    x: 422.3,
                    y: 242.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "B110",
                rotation: 0,
                //min_scale: 1.2,
                x: 402.3,
                y: 245.5,
                size: 2.3,
            },
            tag: {
                name: languageString === 'ru' ? "ЯРВЕТ" : "YARVET",
                x: 426,
                y: 249.5,
                rotation: 0,
                pointerDirection: "up",
                pointerHeight: 4,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.2,
                width: 11
                //min_scale: 2,
            }
        },
        {
            id: "195203",
            shape:
                {
                    x: 432.7,
                    y: 244,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "B111",
                rotation: 0,
                //min_scale: 1.2,
                x: 413,
                y: 246.5,
                size: 2.3,
            },
            tag: {
                name: languageString === 'ru' ? "ПОЛЫМЯ" : "POLYMYA",
                x: 437,
                y: 251.5,
                rotation: 0,
                pointerDirection: "up",
                pointerHeight: 4,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.2,
                width: 10
                //min_scale: 2,
            }
        },
        {
            id: "198255",
            shape:
                {
                    x: 445,
                    y: 246,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "B112",
                rotation: 0,
                //min_scale: 1.2,
                x: 425.5,
                y: 248.5,
                size: 2.3,
            },
            tag: {
                name: languageString === 'ru' ? "AVZ" : "AVZ",
                x: 449,
                y: 252.5,
                rotation: 0,
                pointerDirection: "up",
                pointerHeight: 4,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.2,
                width: 10
                //min_scale: 2,
            }
        },
        {
            id: "195136",
            shape:
                {
                    x: 457.3,
                    y: 248.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "B116",
                rotation: 0,
                //min_scale: 1.2,
                x: 438,
                y: 250.5,
                size: 2.3,
            },
            tag: {
                name: languageString === 'ru' ? "БИНОМ" : "BINOM",
                x: 461,
                y: 255.5,
                rotation: 0,
                pointerDirection: "up",
                pointerHeight: 4,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.2,
                width: 10
                //min_scale: 2,
            }
        },
        {
            id: "195225",
            shape:
                {
                    x: 470.3,
                    y: 250.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "B117",
                rotation: 0,
                //min_scale: 1.2,
                x: 451,
                y: 252.5,
                size: 2.3,
            },
            tag: {
                name: languageString === 'ru' ? "СИББИОНОЦ" : "SIBBIONOC",
                x: 473.5,
                y: 257.5,
                rotation: 0,
                pointerDirection: "up",
                pointerHeight: 4,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.2,
                width: 11
                //min_scale: 2,
            }
        },
        {
            id: "197695",
            shape:
                {
                    x: 482.3,
                    y: 252.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "B118",
                rotation: 0,
                //min_scale: 1.2,
                x: 463,
                y: 254.5,
                size: 2.3,
            },
            tag: {
                name: languageString === 'ru' ? "БДТ-АГРО" : "BDT-AGRO",
                x: 486.5,
                y: 259.5,
                rotation: 0,
                pointerDirection: "up",
                pointerHeight: 4,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.2,
                width: 11
                //min_scale: 2,
            }
        },
        {
            id: "198179",
            shape:
                {
                    x: 494.3,
                    y: 254.8,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12,
                    height: 9,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "B119",
                rotation: 0,
                //min_scale: 1.2,
                x: 474.5,
                y: 257.5,
                size: 2.3,
            },
            tag: {
                name: languageString === 'ru' ? "ASIR REGION COFFEE  ASSOCIATION" : "ASIR REGION COFFEE  ASSOCIATION",
                x: 498.5,
                y: 261.5,
                rotation: 0,
                pointerDirection: "up",
                pointerHeight: 4,
                pointerWidth: 3,
                cornerRadius: 1,
                size: 1.2,
                width: 11
                //min_scale: 2,
            }
        },
        {
            id: "195089",
            shape:
                {
                    x: 358,
                    y: 220.8,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12.4,
                    height: 10,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C110",
                rotation: 0,
                //min_scale: 1.2,
                x: 338.4,
                y: 224,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "9АГРО" : "9AGRO",
                x: 365,
                y: 222.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 11
                //min_scale: 2,
            }
        },
        {
            id: "195098",
            shape:
                {
                    x: 370,
                    y: 222.8,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12.4,
                    height: 10,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C111",
                rotation: 0,
                //min_scale: 1.2,
                x: 350.4,
                y: 226,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "IBS" : "IBS",
                x: 377.5,
                y: 224.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 11
                //min_scale: 2,
            }
        },
        {
            id: "195133",
            shape:
                {
                    x: 384,
                    y: 225,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12.4,
                    height: 10,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C112",
                rotation: 0,
                //min_scale: 1.2,
                x: 364.6,
                y: 228,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "БЕЛГОРОДСКИЕ КОРМА" : "BELGOROD FEED",
                x: 390.5,
                y: 226.5,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195154",
            shape:
                {
                    x: 398,
                    y: 227.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12.4,
                    height: 10,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C113",
                rotation: 0,
                //min_scale: 1.2,
                x: 378.6,
                y: 230.5,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "ГЕОМИРАГРО" : "GEOMIRAGRO",
                x: 404.5,
                y: 228.8,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195116",
            shape:
                {
                    x: 410,
                    y: 229.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12.4,
                    height: 10,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C114",
                rotation: 0,
                //min_scale: 1.2,
                x: 390.6,
                y: 232.5,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "АГРОВЕТ" : "AGROVET",
                x: 418.5,
                y: 231.8,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2.3,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195151",
            shape:
                {
                    x: 434.3,
                    y: 233.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12.4,
                    height: 10,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C115",
                rotation: 0,
                //min_scale: 1.2,
                x: 414.6,
                y: 236.5,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "ВИТЕБСКИЕ СЕМЕНА ТРАВ" : "VITEBSK HERB SEEDS",
                x: 440.5,
                y: 234.8,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2.3,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "195178",
            shape:
                {
                    x: 446.3,
                    y: 235.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12.4,
                    height: 10,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C116",
                rotation: 0,
                //min_scale: 1.2,
                x: 426.6,
                y: 238.5,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "ТГУ" : "TSU",
                x: 452.5,
                y: 236.8,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2.3,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 10
                //min_scale: 2,
            }
        },
        {
            id: "197611",
            shape:
                {
                    x: 459.3,
                    y: 238,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12.4,
                    height: 10,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C117",
                rotation: 0,
                //min_scale: 1.2,
                x: 439.6,
                y: 240.5,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "СОКО" : "CO-KO",
                x: 465.5,
                y: 239.8,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2.3,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 10
                //min_scale: 2,
            }
        },
        {
            id: "195228",
            shape:
                {
                    x: 472.3,
                    y: 240,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12.4,
                    height: 10,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C118",
                rotation: 0,
                //min_scale: 1.2,
                x: 452.6,
                y: 242.5,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "СИВЕТРА-АГРО" : "SIVETRA-AGRO",
                x: 478.5,
                y: 241,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2.3,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },
        {
            id: "198117",
            shape:
                {
                    x: 483.3,
                    y: 242,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12.4,
                    height: 10,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C119",
                rotation: 0,
                //min_scale: 1.2,
                x: 463.6,
                y: 244.5,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "ИНТЕРГЕН" : "INTERGEN",
                x: 490.5,
                y: 244,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2.5,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 10
                //min_scale: 2,
            }
        },
        {
            id: "197576",
            shape:
                {
                    x: 494.3,
                    y: 244,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12.4,
                    height: 10,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C120",
                rotation: 0,
                //min_scale: 1.2,
                x: 475,
                y: 246.5,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "АГРОПЛЕМ" : "AGROPLEM",
                x: 501.5,
                y: 245,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2.5,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 10
                //min_scale: 2,
            }
        },
        {
            id: "196255",
            shape:
                {
                    x: 506.3,
                    y: 246,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12.4,
                    height: 10,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C121",
                rotation: 0,
                //min_scale: 1.2,
                x: 487,
                y: 248.5,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "ПОЛИДОН" : "POLYDON",
                x: 512.5,
                y: 247,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2.5,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 10
                //min_scale: 2,
            }
        },
        {
            id: "197699",
            shape:
                {
                    x: 518.3,
                    y: 248,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    // stroke: "black",
                    fill: 'green',
                    width: 12.4,
                    height: 10,
                    closed: true,
                    rotation: 0
                },
            text: {
                label: "C122",
                rotation: 0,
                //min_scale: 1.2,
                x: 499,
                y: 250.5,
                size: 2.4,
            },
            tag: {
                name: languageString === 'ru' ? "АГМ-ПАРТС" : "AGM PARTS",
                x: 525.5,
                y: 249,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 2.5,
                pointerWidth: 4,
                cornerRadius: 1,
                size: 1.3,
                width: 13
                //min_scale: 2,
            }
        },


    ]



    const stageRef = useRef(null);
    let lastCenter = null;
    let lastDist = 0;

    /*
        useEffect(() => {
            if (image) {
                console.log('add event listener');
                image.addEventListener('load', handleLoad);
            }
            return () => image.removeEventListener('load', handleLoad);
        }, []);
    */



    const handleLoad = () => {
        // after setState react-konva will update canvas and redraw the layer
        // because "image" property is changed
        // console.log('onLoad Fired!')
    }

    function zoomStage(event) {

        event.evt.preventDefault();
        if (stageRef.current !== null) {
            const stage = stageRef.current;
            const oldScale = stage.scaleX();
            const { x: pointerX, y: pointerY } = stage.getPointerPosition();
            const mousePointTo = {
                x: (pointerX - stage.x()) / oldScale,
                y: (pointerY - stage.y()) / oldScale,
            };
            const newScale = event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
            stage.scale({ x: newScale, y: newScale });
            const newPos = {
                x: pointerX - mousePointTo.x * newScale,
                y: pointerY - mousePointTo.y * newScale,
            }
            stage.position(newPos);
            stage.batchDraw();
            //  console.log('zoomStage', );

            setCurrentZoomLevel(newScale);
            //    console.log('Current zoom level:', newScale);


        }
    }

    function handleTouch(e) {

        //   console.log('handleTouchStart');

        e.evt.preventDefault();
        var touch1 = e.evt.touches[0];
        var touch2 = e.evt.touches[1];
        const stage = stageRef.current;
        if (stage !== null) {
            if (touch1 && touch2) {
                if (stage.isDragging()) {
                    stage.stopDrag();
                }

                const p1 = {
                    x: touch1.clientX,
                    y: touch1.clientY
                };
                const p2 = {
                    x: touch2.clientX,
                    y: touch2.clientY
                };

                if (!lastCenter) {
                    lastCenter = getCenter(p1, p2);
                    return;
                }
                const newCenter = getCenter(p1, p2);

                const dist = getDistance(p1, p2);

                if (!lastDist) {
                    lastDist = dist;
                }

                // local coordinates of center point
                const pointTo = {
                    x: (newCenter.x - stage.x()) / stage.scaleX(),
                    y: (newCenter.y - stage.y()) / stage.scaleX()
                };

                const scale = stage.scaleX() * (dist / lastDist);

                stage.scaleX(scale);
                stage.scaleY(scale);

                // calculate new position of the stage
                const dx = newCenter.x - lastCenter.x;
                const dy = newCenter.y - lastCenter.y;

                const newPos = {
                    x: newCenter.x - pointTo.x * scale + dx,
                    y: newCenter.y - pointTo.y * scale + dy
                };

                stage.position(newPos);
                stage.batchDraw();

                lastDist = dist;
                lastCenter = newCenter;
            }
        }
    }

    function handleTouchEnd() {
        lastCenter = null;
        lastDist = 0;
        // console.log('handleTouchEnd');
    }

    function handleTouchStart() {
        lastCenter = null;
        lastDist = 0;
        // console.log('handleTouchStart');
    }

    const [modalVisible, setModalVisible] = useState(false);

    const handleClick = (e) => {
        //   console.log("Stage clicked, XY = ", e.evt.x + ' : ' + e.evt.y, e);
    };


    const handleLineClick = (e) => {
        if (!isOnline(true, languageString)) {

        } else {
            const id = e.target.attrs.id;
            setCurrentExponentId(id);
            setModalVisible(true);
            //       console.log("Line clicked, ID = ", id);
        }


    };

    if (imageWidth === 0)
        return (
            <Dimmer active inverted>
                <Loader active>{t("loadInfo")}...</Loader>
            </Dimmer>
        );

    return (
        <>
            <Stage
                width={window.innerWidth}
                height={window.innerHeight}
                onClick={handleClick}
                draggable={!isTouchEnabled()}
                onWheel={zoomStage /*handleWheel*/}
                onTouchMove={handleTouch}
                onTouchEnd={handleTouchEnd}
                onTouchStart={handleTouchStart}

                ref={stageRef}
            >
                <Layer>

                    {image && (
                        <>
                            <KonvaImage
                                image={image}
                                width={924}
                                height={615}
                                scaleX={scale}
                                scaleY={scale}
                                x={offsetX}
                                y={offsetY}
                            />
                        </>

                    )}
                    {shapes.map((shape, index) => {

                        const isHighlighted = highlightedShapes.includes(shape.id);

                        return (
                            <>
                                {/*<Line*/}
                                {/*    x={shape.shape.x}*/}
                                {/*    y={shape.shape.y}*/}
                                {/*    id={shape.id}*/}
                                {/*    closed={shape.shape.closed}*/}
                                {/*    stroke={shape.shape.stroke}*/}
                                {/*    fill={isHighlighted ? 'black' : shape.shape.fill}*/}
                                {/*    points={shape.shape.points}*/}
                                {/*    listening={shape.id !== "1"}*/}
                                {/*    onClick={handleLineClick}*/}
                                {/*    onTouchStart={handleLineClick}*/}
                                {/*/>*/}

                                <KonvaImage
                                    image={shape.shape.fill === "blue" ? blueSquare : shape.shape.fill === "red1" ? redSquareHor : shape.shape.fill === "red2" ? redSquareVer : shape.shape.fill === "yellowbig" ? yellowSquareBig : shape.shape.fill === "yellow" ? yellowSquare : shape.shape.fill === "green" ? greenSquare : ''}
                                    width={shape.shape.width}
                                    height={shape.shape.height}
                                    scaleX={scale}
                                    scaleY={scale}
                                    x={shape.shape.x}
                                    y={shape.shape.y}
                                    id={shape.id}
                                    rotation={shape.shape.rotation}
                                    onClick={handleLineClick}
                                    onTouchStart={handleLineClick}
                                />


                                {isMobile ? (
                                    <Label x={shape.tag.x} y={shape.tag.y} listening={false}>
                                        <Tag
                                            fill={shape.id === "1" ? "transparent" : "black"}
                                            pointerDirection={shape.tag.pointerDirection}
                                            pointerHeight={shape.tag.pointerHeight}
                                            pointerWidth={shape.tag.pointerWidth}
                                            cornerRadius={shape.tag.cornerRadius}
                                        />
                                        <KonvaText fontSize={shape.tag.size} text={shape.tag.name} fontFamily='Muller' fill="white" padding={1} width={shape.tag.width} align="center"/>
                                    </Label>
                                ) : min_scale_tag_label <= currentZoomLevel ? (
                                    <Label x={shape.tag.x} y={shape.tag.y} listening={false}>
                                        <Tag
                                            fill={shape.id === "1" ? "transparent" : "black"}
                                            pointerDirection={shape.tag.pointerDirection}
                                            pointerHeight={shape.tag.pointerHeight}
                                            pointerWidth={shape.tag.pointerWidth}
                                            cornerRadius={shape.tag.cornerRadius}
                                        />
                                        <KonvaText fontSize={shape.tag.size} text={shape.tag.name} fill='white' fontFamily='Muller' padding={1} width={shape.tag.width} align="center"/>
                                    </Label>
                                ) : ''}

                                {isMobile ? (
                                    <Label x={shape.text.x} y={shape.text.y} listening={false}>
                                        <KonvaText fontFamily="Muller" fontSize={shape.text.size} fill={isHighlighted || shape.shape.fill === "#a62121" || shape.shape.fill === "purple" || shape.id === "1" ? "white" : "black"} text={shape.text.label} width={50} align="center" rotation={shape.text.rotation}/>
                                    </Label>
                                ) : min_scale_text_label <= currentZoomLevel ? (
                                    <Label x={shape.text.x} y={shape.text.y} listening={false}>
                                        <KonvaText fontFamily="Muller" fontSize={shape.text.size} fill={isHighlighted || shape.shape.fill === "#a62121" || shape.shape.fill === "purple" || shape.id === "1" ? "white" : "black"} text={shape.text.label} width={50} align="center" rotation={shape.text.rotation}/>
                                    </Label>
                                ) : ''}


                            </>
                        )

                    })}

                    {/*<Circle*/}
                    {/*    x={213.6}*/}
                    {/*    y={665}*/}
                    {/*    id="1"*/}
                    {/*    radius={21.5}*/}
                    {/*    fill="green"*/}
                    {/*    listening={false}*/}
                    {/*/>*/}
                    {/*{isMobile ? (*/}
                    {/*    <Label x={188} y={660} listening={false}>*/}
                    {/*        <KonvaText fontFamily="Muller" fontSize={6} fill="white" text={languageString === "ru" ? "Купольный\nкинотеатр" : "Dome\nCinema"} width={50} align="center" rotation={0}/>*/}
                    {/*    </Label>*/}
                    {/*) : min_scale_text_label <= currentZoomLevel ? (*/}
                    {/*    <Label x={188} y={660} listening={false}>*/}
                    {/*        <KonvaText fontFamily="Muller" fontSize={6} fill="white" text={languageString === "ru" ? "Купольный\nкинотеатр" : "Dome\nCinema"} width={50} align="center" rotation={0}/>*/}
                    {/*    </Label>*/}
                    {/*) : ''}*/}


                </Layer>

            </Stage>

            {modalVisible && currentExponentId &&
                <ExponentModal
                    exponentId={currentExponentId}
                    closeModal={() => setModalVisible(false)}
                    token={token}
                    languageString={languageString}
                    dispatch={dispatch}
                    navigate={navigate}
                />
            }

            <Dimmer active={isFetching} inverted>
                <Loader active={isFetching}>{t("loadInfo")}...</Loader>
            </Dimmer>

        </>
    );
};

const ExponentModal = ({ exponentId, closeModal, token, languageString, dispatch, navigate }) => {
    const [exponent, setExponent] = useState(null);
    const exponents = useSelector((state) => getExponents(state.exponents));
    const isFetching = useSelector((state) => getExponentsFetching(state.exponents));
    const language = useSelector(getLanguage);

    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const readExponents = (id) => {
        const params = {
            page: 1,
            per_page: 1,
            order: 'asc',
            id: id,
            orderby: 'upper(name)',
            event: 'ASIAEXPO 2024',
            lang: languageString,
        };

        dispatch(fetchExponents(token, params, languageString));
    };

    useEffect(() => {
        const exponentData = exponents.find(exp => exp.id === exponentId);

        if (exponentData) {
            setExponent(exponentData);
        } else {
            readExponents(exponentId);
        }
    }, [exponentId, dispatch, token, languageString]);

    useEffect(() => {
        const updatedExponent = exponents.find(exp => exp.id === exponentId);
        if (updatedExponent) {
            setExponent(updatedExponent);
        }
    }, [exponents, exponentId]);

    return (
        <Modal
            open={true}
            onClose={closeModal}
            className={isFetching ? "modal-exposition-load" : "modal-exposition"}
            closeIcon
            closeOnDimmerClick
            centered
        >
            <Modal.Content className='modal-exposition-content'>

                {isFetching
                    ?     <Dimmer active={isFetching} inverted>
                        <Loader active={true}>{t("loadInfo")}...</Loader>
                    </Dimmer>
                    : (
                        <>
                            <div className='container-data-exponent'>
                                <Image className='img-modal-exponent' src={exponent?.logo}/>
                                <div className='text-modal-exponent'>
                                <span className='subject-modal-exponent'>
                                    {languageString === "ru" ? exponent?.name : exponent?.name_en}
                                </span>
                                    <span className='desc-modal-exponent'>
                                    {languageString === "ru" ? exponent?.excerpt : exponent?.excerpt_en}
                                </span>
                                </div>
                            </div>

                            <Arrow className='more-arrow'
                                   onClick={() => navigate(`/archive-events/congress-asiaexpo-2024/exponents/exponents-card/${exponent?.id}`)}/>
                            <span className='text-more-exp-exposition'
                                  onClick={() => navigate(`/archive-events/congress-asiaexpo-2024/exponents/exponents-card/${exponent?.id}`)}>
                                 {t("details")}
                            </span>
                        </>
                    )}
            </Modal.Content>
        </Modal>
    );
};

export default Exposition;