import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'semantic-ui-react'

import './style.css';
import AccessChecker from "../AccessChecker";


import ExportIcon from "../../icons/export-icon.png";
import translations from "../../components/LanguageModal/translations";
import {useSelector} from "react-redux";
import {getLanguage} from "../../components/LanguageModal/reducer";
import {fetchTicketData} from "../AccessChecker/actions";
import {fetchUserData} from "../../components/UserLogin/actions";
import VCard from "../../components/VCard";

export default function Scan(props) {
    const {token, dispatch, mixpanel} = props;
    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const [open, setOpen] = useState(false);

    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        if (mixpanel) {
            mixpanel.track('View. Scan QR')
        }
    }, []);


    const handleSubmit = () => {
        const params = {
            ticket: scanResult,
            lang: languageString
        }

        dispatch(fetchTicketData(token, params)).
            then(() => dispatch(fetchUserData(token)))
    }
    const returnScanView = () => {
       return  <>
            <div className="scan-text">
                {t("pointCamera")}
            </div>
            {/*<div className='scan-icon-container'>*/}
            {/*    <CornerIcon className="scan-photo-icon"/>*/}
            {/*    <PhotoIcon className="scan-photo-icon"/>*/}
            {/*</div>*/}

            <AccessChecker
                token={token}
            />

            {/*<Button className='scan-btn'>*/}
            {/*    <div className="scan-btn-text">{t("export")}</div>*/}
            {/*    <img src={ExportIcon} className="scan-export-icon"/>*/}
            {/*</Button>*/}
        </>
    }


    return (
        <div className="scan-view">
            {returnScanView()}
        </div>
    );
}
