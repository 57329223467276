import {Link} from "react-router-dom";
import {Image} from "semantic-ui-react";
import React, {useEffect} from "react";

import "./style.css";

import { ReactComponent as VectorIcon } from '../../icons/vector-icon.svg';
import {getLanguage} from "../../components/LanguageModal/reducer";
import {useSelector} from "react-redux";
import translations from "../../components/LanguageModal/translations";


export default function Home (props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    return (
        <>

            <div className="block-container">
                <div className="container-ticket">
                    <Link className="fimg" to='/archive-events/congress-asiaexpo-2023'>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/congress.png'
                               className='congress-icon'/>

                        <p className="text-for-tickets">{t("congress")} <br/>{t("ae2023")}</p>

                        <VectorIcon className="arrow-icon"/>
                    </Link>
                </div>

                <div className="container-ticket">
                    <Link className="fimg" to='/archive-events/congress-asiaexpo-2024'>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/congress.png'
                               className='congress-icon'/>

                        <p className="text-for-tickets">{t("congress")} <br/>{t("ae2024")}</p>

                        <VectorIcon className="arrow-icon"/>
                    </Link>
                </div>


            </div>
        </>
    );
}