import React, {useEffect, useState} from "react";
import './style.css'
import {YMaps, Map, Placemark, ZoomControl} from '@pbe/react-yandex-maps';
import ModalImageGallery from "../../../components/ModalImageGallery";
import {Image} from "semantic-ui-react";
import {useSelector} from "react-redux";
import {getLanguage} from "../../../components/LanguageModal/reducer";
import translations from "../../../components/LanguageModal/translations";
import SadIcon from "../../../icons/sad-icon.png";



export default function DescriptionCongress(props) {
    const { mixpanel } = props
    const defaultState = {
        center: [43.414713, 39.950758],
        zoom: 15,
        controls: [],
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (mixpanel) {
            mixpanel.track('View. Description Congress')
        }
    }, []);

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const [modalActive, setModalActive] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    const modalImageGallery = () => (
        <ModalImageGallery
            open={modalActive}
            handleOpen={() => setModalActive(true)}
            handleClose={() => setModalActive(false)}
            images={[
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_3-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_3-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_2-scaled.jpeg",
                    thumbnail: " https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_2-scaled.jpeg",
                },
            ]}
            showAsGallery={false}
            index={imageIndex}
        />);

        const onImgClick = (index) => {
            setImageIndex(index);
            setModalActive(true);
        }

        const GalleryCongress = () => {
            if (navigator.onLine) {
                return (
                    <>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress' onClick={() => (onImgClick(0))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_3-scaled.jpeg'
                               className='picture-congress' onClick={() => (onImgClick(1))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_2-scaled.jpeg'
                               className='picture-congress' onClick={() => (onImgClick(2))}/>
                    </>
                )
            } else {
                return (
                    <div className='view-images'>
                        <img src={SadIcon} alt={""}/>
                        <span className='text-bookmark'>{languageString === 'ru'
                            ? 'Для просмотра изображений, пожалуйста, подключитесь к интернету'
                            : 'To view the images, please connect to the Internet'}</span>
                    </div>
                )
            }
        }


    return (
        <>
            <div className="container">
                <div id='cong-title' className="title-text">{t('aboutCongressTitle')}</div>
                <div className="main-text">
                    {t('aboutTheCongressOne')}
                </div>
                <div className="main-text">
                    {t('aboutTheCongressTwo')}
                </div>

                <div className={'cong-indent'}></div>

                <div className="title-text">{t('congressBlocks')}</div>
                <div className="main-text">
                    <b>{t('exh')}</b> {t('blockCongressOne')}
                </div>
                <div className="main-text">
                    <b>{t('busProgram')}</b> {t('blockCongressTwo')}
                </div>
                <div className="main-text">
                    <b>{t('culProgram')}</b> {t('blockCongressOne')}
                </div>

                <div className='pictures-congress'>
                    {GalleryCongress()}
                </div>

                <div className={'cong-indent'}></div>

                <div className="title-text">{t('dateEvent')}</div>
                <div id='event-date' className="main-text"><b>{t('oc23_25')}</b></div>

                <div className="subtitle-text">{t('congressHours')}</div>
                <div id='event-time' className="main-text">{t('oc23')}</div>
                <div id='event-time' className="main-text">{t('oc24')}</div>
                <div id='event-time' className="main-text">{t('oc25')}</div>

                <div className={'cong-indent'}></div>

                <div className="title-text">{t('locationEvent')}</div>
                <div id='university' className="main-text">
                    <b>{t('sirius')}</b>
                </div>

                <div className="main-text">
                    {t('univerSirius')}
                </div>
                <div className="main-text">
                    {t('afterGames')}
                </div>
                <div className="main-text">
                    {t('exhComplex')}
                </div>
                <div className={'ymap'}></div>
                {navigator.onLine ?
                    (<YMaps query={languageString === "en" ? { lang: 'en_RU' } : { lang: "ru_RU"}}>
                    <Map
                        defaultState={defaultState}
                        width={"100%"}
                    >
                        <ZoomControl options={{float: "right"}}/>
                        <Placemark geometry={[43.414713, 39.950758]}/>
                    </Map>
                </YMaps>) : (
                        // <div>
                        //     {languageString === 'ru'
                        //         ? 'Для просмотра карты, пожалуйста, подключитесь к интернету'
                        //         : 'To view the map, please connect to the Internet'}
                        // </div>
                        <div className='view-map'>
                            <img src={SadIcon} alt={""}/>
                            <span className='text-bookmark'>{languageString === 'ru'
                                        ? 'Для просмотра карты, пожалуйста, подключитесь к интернету'
                                        : 'To view the map, please connect to the Internet'}</span>
                        </div>
                    )}
            </div>
            {modalImageGallery()}
        </>
    );
}