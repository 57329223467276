import React from 'react';
import { useSelector } from 'react-redux';

import {
    Card, Loader, Dimmer
} from 'semantic-ui-react';
import {getOrdersFetching, getPlaceOrderInProgress} from "../../views/Orders/reducer";
import MiniOrderCard from "./MiniOrderCard";
import CardSummary from "../CardSummary/CardSummary";
import {isCountriesFetching} from "../AddressCard/reducer";


export default function OrderCard(props) {

    const {order} = props;

    const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));
    const fetchOrders = useSelector((state) => getOrdersFetching(state.orders));
    const getLoadingCountries = useSelector((state) => isCountriesFetching(state.address));

    return (
        <div>
            <Card centered color="orange" className="order-card">
                <Dimmer active={ [1, order.id].includes(getLoading) || getLoadingCountries || fetchOrders } inverted>
                    <Loader className="order-update-loader"/>
                </Dimmer>
                <MiniOrderCard
                    key={order.id}
                    order={order}
                    header={false}
                    circular={true}
                    actionButtons={true}
                    {...props}
                />
            </Card>
            <CardSummary
                total_products={order.total}
                show_shipping = {false}
            />
        </div>
    );
}