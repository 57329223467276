import React from 'react';
import { Step, Icon } from 'semantic-ui-react';
import {useSelector} from "react-redux";
import {getTotalPrice, hasOnlyVirtuals} from "../views/Cart/reducer";
import {getLanguage} from "./LanguageModal/reducer";
import translations from "./LanguageModal/translations";

export const CHECKOUT_STEP_SHIPPING = 1;
export const CHECKOUT_STEP_PAYMENT = 2;
export const CHECKOUT_STEP_CONFIRM = 3;

export default function CheckoutSteps(props) {

  const total_price = useSelector((state) => getTotalPrice(state.cart));
  const language = useSelector(getLanguage);
  const languageString = language[0] + language[1];

  const t = (key) => {
    const translation = translations[languageString];
    return translation ? translation[key] : key;
  };
    return (
      <Step.Group
        unstackable
        fluid
        className="checkout-steps-group"
      >
        <Step
          active={props.step === CHECKOUT_STEP_SHIPPING}
          completed={props.step > CHECKOUT_STEP_SHIPPING}
          className="checkout-step"
        >
          <Icon name="user" className="checkout-steps-icon" />
          <Step.Content>
            <Step.Description className="checkout-steps-description">
              {t('customer')}
            </Step.Description>
          </Step.Content>
        </Step>

        {total_price ?
          <Step
            disabled={props.step < CHECKOUT_STEP_PAYMENT}
            active={props.step === CHECKOUT_STEP_PAYMENT}
            completed={props.step > CHECKOUT_STEP_PAYMENT}
            className="checkout-step"
          >
            <Icon name="payment" className="checkout-steps-icon" />
            <Step.Content>
              <Step.Description className="checkout-steps-description">
                {t('payment')}
              </Step.Description>
            </Step.Content>
          </Step>
        : ''}

        <Step
          disabled={props.step < CHECKOUT_STEP_CONFIRM}
          active={props.step === CHECKOUT_STEP_CONFIRM}
          className="checkout-step"
        >
          <Icon name="ticket" className="checkout-steps-icon" />
          <Step.Content>
            <Step.Description className="checkout-steps-description">
              {t("tiket")}
            </Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    );
}

