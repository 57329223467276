import { combineReducers } from 'redux';
import {REQUEST_NOTIFICATIONS, RECEIVE_NOTIFICATIONS, NOTIFICATIONS_PAGE_COUNT} from './actions';
import {mergeObjectArrays} from "../../configureStore";

const items = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_NOTIFICATIONS:
            if (action.page === 1)
                return action.notifications;
            else
                return mergeObjectArrays(action.notifications, state);
        default:
            return state;
    }
};

const hasMore = (state = false, action = 0) => {
    switch (action.type) {
        case REQUEST_NOTIFICATIONS:
            return true;
        case RECEIVE_NOTIFICATIONS:
            return action.notifications.length >= NOTIFICATIONS_PAGE_COUNT;
        default:
            return state;
    }
};

const isFetching = (state = false, action) => {
    switch (action.type) {
        case REQUEST_NOTIFICATIONS:
            return true;
        case RECEIVE_NOTIFICATIONS:
            return false;
        default:
            return state;
    }
};

export const getNotifications = (state) => state.items;
export const getNotificationsFetching = (state) => state.isFetching;
export const getNotificationsHasMore = (state) => state.hasMore;
export const getNotificationsPage = (state) => state.page;

export default combineReducers({
    items,
    isFetching,
    hasMore,
});
