import React  from "react";
import { useSelector } from 'react-redux';
import "./style.css";
import {Loader} from "semantic-ui-react";
import { ReactComponent as Bookmark } from '../../icons/bookmark-icon.svg';
import { ReactComponent as BookmarkGray } from '../../icons/bookmark-icon-gray.svg';

import {updateUserData} from "../UserLogin/actions";
import {getUserData, getUserFetching, getUserUpdatingQuery} from "../UserLogin/reducer";
import {toastr} from "react-redux-toastr";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";

export default function BookmarkComponent (props) {

    const { id, bookmark_using_for, token, dispatch, location, exponentId, mixpanel} = props;
    const userData = useSelector((state) => getUserData(state.userLogin));
    const isUserFetching = useSelector((state) => getUserFetching(state.userLogin));
    const userUpdatingQuery = useSelector((state) => getUserUpdatingQuery(state.userLogin));

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    let updatedBookmark = 0;
    if (userUpdatingQuery.length > 0)
        updatedBookmark = JSON.parse(userUpdatingQuery)['wishlist_' + bookmark_using_for] ?? 0;

    const isBookmarked = typeof userData.wishlists === 'undefined' ? false : userData.wishlists[bookmark_using_for].includes(id);
    //console.log('component bookmark for ' + bookmark_using_for, isBookmarked, userData, id, isUserFetching, userUpdatingQuery)

    const toggleBookmark = () => {
        // const updatedBookmark = !isBookmarked;
        // setIsBookmarked(updatedBookmark);
        // localStorage.setItem(`isBookmarked${id}`, JSON.stringify(updatedBookmark));
        //
        // if (!updatedBookmark) {
        //     localStorage.removeItem(`bookmark1`);
        // }
        if (!token)
            if (location.pathname === "/exponents" || location.pathname.startsWith('/exponents/exponents-card/') || location.pathname === "/archive-events/congress-asiaexpo-2023/exponents" || location.pathname.startsWith('/archive-events/congress-asiaexpo-2023/exponents/exponents-card'))
                toastr.warning(t("bookmarkExponent"));
            else {
                toastr.warning(t("bookmarkForum"));
            }
        else {
            const $query = {};
            $query['wishlist_' + bookmark_using_for] = id;
            dispatch(updateUserData(token, userData.id, JSON.stringify($query), true, languageString));
            if (mixpanel) {
                mixpanel.track('Action. Bookmark' + ' ' + bookmark_using_for)
            }
        }
    };

    return (
        <>
            {
                isUserFetching && Number(updatedBookmark) === Number(id)
                    ? <Loader active inline className="bookmark loader"/>
                    : isBookmarked
                        ? <Bookmark className="bookmark image" onClick={navigator.onLine ? toggleBookmark : toastr.warning(languageString === "ru"
                            ? "Вы не в сети. Пожалуйста, подключитесь к Интернету"
                            : 'You are offline. Please connect to the Internet')}/>
                        : <BookmarkGray className="bookmark image" onClick={navigator.onLine ? toggleBookmark : toastr.warning(languageString === "ru"
                            ? "Вы не в сети. Пожалуйста, подключитесь к Интернету"
                            : 'You are offline. Please connect to the Internet')}/>
            }
        </>
    );
}