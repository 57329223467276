import React, {useEffect} from 'react';
import {Header, Button, Card, Loader, Dimmer} from 'semantic-ui-react';
import {useSelector} from 'react-redux';
import {Link}  from 'react-router-dom';

import './styles.css';
import CardSummary from '../../components/CardSummary/CardSummary';
import CheckoutSteps, { CHECKOUT_STEP_PAYMENT } from '../../components/CheckoutSteps';
import {getTotalPrice, hasOnlyVirtuals} from '../Cart/reducer';
import { getShippingCost, getShippingMethod, getShippingType } from '../../components/ShippingCard/reducer';
import PaymentOptions from './PaymentOptions';

import { getAddressFields} from '../../components/AddressCard/reducer';
import { getPaymentMethod } from './reducer';
import CouponCard from '../../components/CouponCard';
import ConfirmOrderButton from "../../components/ConfirmOrderButton";
import {getPlaceOrderInProgress} from "../Orders/reducer";
import {PAYMENT_OPTION_BANK} from "./actions";
import {getDiscount} from "../../components/CouponCard/reducer";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import {removeCoupon} from "../../components/CouponCard/actions";

export default function CheckoutStepPayment(props) {

  const {dispatch, userData, userLocation, mixpanel, isLoggedIn} = props;

  const only_virtuals = useSelector((state) => hasOnlyVirtuals(state.cart));
  const shipping_type = only_virtuals? '' : useSelector((state) => getShippingType(state.shipping));
  const shipping_method = only_virtuals ? '' : useSelector((state) => getShippingMethod(state.shipping));

  const address_fields = useSelector((state) => getAddressFields(state.address));
  const payment_option = useSelector((state) => getPaymentMethod(state.payment));
  const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));

  const discount = useSelector((state) => getDiscount(state.discount));
  const total_products = useSelector((state) => getTotalPrice(state.cart)) -
      (discount.data && discount.data.discount ? discount.data.discount : 0);
  const total_shipping = useSelector((state) => getShippingCost(state.shipping));
  const total_price = total_products + (total_shipping ?? 0);

  const coupon = useSelector((state) => getDiscount(state.discount));
  const couponCode = coupon?.data?.coupon ?? ''
  
  const language = useSelector(getLanguage);
  const languageString = language[0] + language[1];

  const t = (key) => {
      const translation = translations[languageString];
      return translation ? translation[key] : key;
  };

  useEffect(() => {
      if (mixpanel)
          mixpanel.track('Checkout Payment', { ...userLocation, ...userData, payment_option, address_fields, total_products, total_shipping });
      window.scrollTo(0, 0);

      dispatch(removeCoupon(couponCode));
  }, []);

  // console.log(payment_option)
  return (
      <div>
          <CheckoutSteps step={CHECKOUT_STEP_PAYMENT}/>
          <Header textAlign="center" className="checkout-payment-header">{t('paymentMethod')}</Header>
          <Dimmer active={getLoading === 1} inverted>
              <Loader inverted> {t('CreatingOrder')}</Loader>
          </Dimmer>
          <CardSummary
              total_products={total_products}
              total_shipping={total_shipping}
              show_shipping={!only_virtuals}
          />
          {total_price > 0 ?
              <CouponCard {...props}/>
              : ''
          }
          <Card centered className="delivery-details-card">
              <Card.Content className='checkout-payment-options'>
                  {total_price > 0 ?
                      <PaymentOptions
                          paymentOption={payment_option}
                          shippingMethod={shipping_method}
                          shippingType={shipping_type}
                          emailField={isLoggedIn ? userData?.billing?.email : address_fields.email ?? ''}
                          dispatch={dispatch}
                      /> : ''
                  }
              </Card.Content>
          </Card>
          <div className={`checkout-payment-buttons ${isLoggedIn ? 'centered' : ''}`}>
              {!isLoggedIn &&
                  <Link to="/checkout-step-shipping">
                      <Button secondary className="checkout-payment-buttons-button button-back" floated="right">
                          {t('back')}
                      </Button>
                  </Link>
              }
              <ConfirmOrderButton
                  addClassName="checkout-payment-buttons-button"
                  buttonText={t('ConfirmOrder')}
                  pathToRoute="/checkout-step-confirm"
                  disabled={(typeof address_fields.email === 'undefined' || address_fields.email.length === 0) &&
                      payment_option === PAYMENT_OPTION_BANK}
                  {...props}
              />
          </div>

      </div>
  );
}



