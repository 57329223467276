import React, {useEffect} from 'react';
import {setLanguage} from "../../components/LanguageModal/reducer";

export default function EnLocalization (props) {

    const { navigate, dispatch } = props

    useEffect(() => {
        navigate('/')
        dispatch(setLanguage("en"))
    }, []);

    return (
        <>

        </>
    );
}