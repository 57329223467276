import React from 'react';
import {Header, Modal} from 'semantic-ui-react';
import {TINKOFF_SUCCESS_PAGE} from "../../config/config";

import PayForm from "../TinkoffOplata/PayForm";
import {TINKOFF_TERMINAL_ID} from "../../config/secrets";
import {useSelector} from "react-redux";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";

export default function ModalPayment(props) {

    const {open, headerIcon, headerText, order, handleOpen, handleClose, isLoggedIn } = props;
    const [stateLoading, setStateLoading] = React.useState(true);

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    function openPaymentModal() {
        handleOpen();
        setStateLoading(true);
    }

    function closePaymentModal() {
        setLoaded();
        handleClose();
    }

    function setLoaded() {
        setStateLoading( false);
    }

    const formForPay = {
            terminalkey: TINKOFF_TERMINAL_ID,
            frame: 'true',
            language: languageString,
            amount: order.total,
            order: order.id,
            order_key: order.order_key,
            description: t("payWndHeader"),
            name: order.billing.last_name + ' ' + order.billing.first_name,
            email: order.billing.email,
            phone: order.billing.phone,
            successurl: window.location.origin + TINKOFF_SUCCESS_PAGE,
            route: window.location.hash.slice(1),
            handleclose: closePaymentModal,
            handleloading: setLoaded,
            stateLoading: stateLoading,
        };

    return (
        <Modal
            closeIcon
            dimmer
            centered
            open={ open }
            onOpen={ openPaymentModal }
            onClose={ closePaymentModal }
            closeOnDimmerClick={ true }
            className="modal-payment"
        >
            <Header icon={ headerIcon } content={ headerText } />
            <Modal.Content>
                <PayForm formData={ formForPay } {...props}/>
            </Modal.Content>
        </Modal>
    );
}
