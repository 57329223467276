import React, {useEffect} from 'react';

import {Link} from "react-router-dom";

import "../CongressAE2023/style.css"



import ExpositionIcon  from '../../icons/exposition-icon.png';
import BusIcon  from '../../icons/business-program-icon.png';
import CulIcon  from '../../icons/cultural-program-icon.png';
import ExhibitionIcon  from '../../icons/exhibition-icon.png';


import  PhotoIcon  from '../../icons/photobank-icon.png';
import {useSelector} from "react-redux";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import {toastr} from "react-redux-toastr";
import DescIcon from "../../icons/desc-congress-icon.png";
import AsiaMeet from "../../icons/asiameet-icon-for-home.png";
import ArchiveIcon from "../../icons/archive-events-icon.png";
import QrIcon from "../../icons/qr-icon.png";

export default function CongressAE2023 (props) {
    const { navigate } = props

    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    return (
        <>

            <div className="main-block1">
                    <div className="main-container1">
                        <div className="container-cards">
                            <Link className="icontab" to='/archive-events/congress-asiaexpo-2024/description-congress'>
                                <img src={DescIcon}/>
                            </Link>
                            <Link className="label-for-icon" to='/archive-events/congress-asiaexpo-2024/description-congress'>
                                <p className="text-for-icon">{t('descCongress')}</p>
                            </Link>
                        </div>

                        <div className="container-cards">
                            <Link className="icontab" to='/archive-events/congress-asiaexpo-2024/exposition'>
                                <img src={ExpositionIcon}/>
                            </Link>
                            <Link className="label-for-icon" to='/archive-events/congress-asiaexpo-2024/exposition'>
                                <p className="text-for-icon">{t('exposition')}</p>
                            </Link>
                        </div>

                        <div className="container-cards">
                            <div className="icontab" onClick={() => navigate('/archive-events/congress-asiaexpo-2024/business-program')}>
                                <img src={BusIcon}/>
                            </div>
                            <div className="label-for-icon" onClick={() => navigate('/archive-events/congress-asiaexpo-2024/business-program')}>
                                <p className="text-for-icon">{t('busProgram')}</p>
                            </div>
                        </div>

                        <div className="container-cards">
                            <Link className="icontab" to='/archive-events/congress-asiaexpo-2024/cultural-program'>
                                <img src={CulIcon}/>
                            </Link>
                            <Link className="label-for-icon" to='/archive-events/congress-asiaexpo-2024/cultural-program'>
                                <p className="text-for-icon">{t('culProgram')}</p>
                            </Link>
                        </div>

                        <div className="container-cards">
                            <div className="icontab" onClick={() => navigate('/archive-events/congress-asiaexpo-2024/exponents')}>
                                <img src={ExhibitionIcon}/>
                            </div>
                            <div className="label-for-icon" onClick={() => navigate('/archive-events/congress-asiaexpo-2024/exponents')}>
                                <p className="text-for-icon">{t('exhibitors')}</p>
                            </div>
                        </div>




                    </div>
            </div>
        </>
    );
}