import React from "react";
import {useDispatch} from "react-redux";
import './styles.css'
import {useSelector} from "react-redux";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";
import CalendarIcon from "../../icons/calendar-icon.png";
import {updateMeetingStatus} from "../../views/AsiaMeet/actions";



export default function MeetingComponent(props) {
    const dispatch = useDispatch();
    const {
        content = {},
        icon = CalendarIcon,
        cardId,
        cardDate = '',
        cardTime = '',
        cardStatus = {ru: '', en: ''},
        cardActions = [],
        updateFunc,
        token,
    } = props;
    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const buttons = []
    const handleUpdateMeetingStatus = (id, status) => {
        dispatch(updateMeetingStatus(token, id, languageString, status))
            .then(() => {
                updateFunc(1);
            })
    };
    Object.values(cardActions).forEach((action) => {
        if (action.en === 'Accept') {
            buttons.push(
                <button
                    className="meeting-card-btn card-btn-red"
                    onClick={() => handleUpdateMeetingStatus(cardId, 'accepted')}
                >
                    {languageString === "ru" ? action.ru : action.en}
                </button>
            );
        } else if (action.en === 'Reject') {
            buttons.push(
                <button
                    className="meeting-card-btn card-btn-gray"
                    onClick={() => {
                        handleUpdateMeetingStatus(cardId, 'rejected');
                    }}
                >
                    {languageString === "ru" ? action.ru : action.en}
                </button>
            );
        } else if (action.en === 'Cancel') {
            buttons.push(
                <button
                    className="meeting-card-btn card-btn-gray"
                    onClick={() => {
                        handleUpdateMeetingStatus(cardId, 'canceled');
                    }}
                >
                    {languageString === "ru" ? action.ru : action.en}
                </button>
            );
        } else if (action.en === 'Complete') {
            buttons.push(
                <button
                    className="meeting-card-btn card-btn-red"
                    onClick={() => {
                        handleUpdateMeetingStatus(cardId, 'completed');
                    }}
                >
                    {languageString === "ru" ? action.ru : action.en}
                </button>
            );
        }
        else if (action.en === 'Cancel') {
            buttons.push(
                <button
                    className="meeting-card-btn card-btn-gray"
                    onClick={() => {
                        handleUpdateMeetingStatus(cardId, 'canceled');
                    }}
                >
                    {languageString === "ru" ? action.ru : action.en}
                </button>
            );
        }
    });

    return (
        <div className="meeting-card" id={cardId}>
            <div className="meeting-card-time">
                <div className="meeting-card-time-container">
                    <img src={icon}/>
                    <div className="meeting-card-time-text">{cardDate.split('-').reverse().join('.') + ' ' + cardTime}</div>
                    <div className="meeting-card-time-text">{languageString === 'ru' ? cardStatus.ru : cardStatus.en}</div>
                </div>
            </div>
            <div>
                <div className="meeting-card-container">
                    <div className="meeting-card-text-container">
                        {content}
                    </div>
                    <div className="meeting-card-btn-container">
                        {buttons.map((button) => (
                            button
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
