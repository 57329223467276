import 'whatwg-fetch';
import config, {AUTH_INVALID_MESSAGE, AUTH_INVALID_TOKEN, SERVICE_LOGICAL_ACCESS_DENIED} from '../../config/config';
import _ from "lodash";
import {toastr} from "react-redux-toastr";
import {dropToken} from "../UserLogin/actions";
import {checkoutOrderPlaced} from "../../views/Orders/actions";

export const REQUEST_APPLY_COUPON = 'REQUEST_APPLY_COUPON';
export const RECEIVE_APPLY_COUPON = 'RECEIVE_APPLY_COUPON';
export const REMOVE_COUPON = 'REMOVE_COUPON';

export const removeCoupon = couponCode => ({
  type: REMOVE_COUPON,
  couponCode,
});

export const requestApplyCoupon = () => ({
  type: REQUEST_APPLY_COUPON,
});

export const receiveApplyCoupon = couponData => ({
  type: RECEIVE_APPLY_COUPON,
  couponData,
});

const process_api_error = (json, dispatch) => {
  if (json.code === AUTH_INVALID_TOKEN) {
    dispatch(dropToken());
    toastr.error(AUTH_INVALID_MESSAGE);
  } else
  if (json.code === SERVICE_LOGICAL_ACCESS_DENIED)
    toastr.error(json.message);
  else
    toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const fetchApplyCoupon = (couponCode, cart) => (dispatch) => {

  dispatch(requestApplyCoupon());
  const url = config.API_APPLY_DISCOUNT_URL;

  const formData = new FormData();
  formData.append('code', couponCode);
  formData.append('cart', cart);

  return fetch(
    url,
    {
      method: 'POST',
      body: formData,
    },
  )
    .then(response => response.json())
    .then(json => {
        // console.log('fetchApplyCoupon', json);
        dispatch(receiveApplyCoupon(json));
        if (json.code === 200) {
          toastr.success(`Промокод успешно применен!`);
        } else
          process_api_error(json, dispatch);
    })
      .catch( (ex) => {
          dispatch(receiveApplyCoupon([]));
          toastr.error('Ошибка запроса: ' + String(ex))
      });

};
