import React from 'react';

import {
    Button,
    Header, Label,
} from 'semantic-ui-react';
import ModalPrompt from "../ModalPrompt";
import {updateOrder} from "../../views/Orders/actions";
import {useSelector} from "react-redux";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";

export default function OrderCardHeader(props) {

    const language = useSelector(getLanguage);
    const languageString = language[0] + language[1];

    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] : key;
    };

    const {order} = props;
    const {status} = order;

    let orderLabelText = t('unissued'), labelColor = "red", icon = "";
    switch (status) {
        case 'processing':
            orderLabelText = t('inTheWork');
            labelColor = "green";
            icon = "trash";
            break;
        case 'completed':
            orderLabelText = t("completed");
            labelColor = "green";
            icon = "trash";
            break;
        case 'on-hold':
        case 'pending':
            orderLabelText = t("waitingForPayment");
            labelColor = "red";
            icon = "trash";
            break;
        case 'cancelled':
            orderLabelText = t('cancelled');
            labelColor = "gray";
            icon = "undo";
            break;
        default:
            break;
    }

    return (
        <div>
            <Header className="order-card-header" as="h3" color="red" textAlign="center">
                <Label as="a" color={ labelColor } ribon="" >{orderLabelText}</Label>
            </Header>
        </div>
    );
}