import React, {useState, useEffect} from 'react';
import './style.css';
import {useDispatch, useSelector} from "react-redux";


import {Button, Form, FormField, Dropdown, Search, Loader, Dimmer} from 'semantic-ui-react'
import { ReactComponent as ArrowIcon } from "../../icons/arrow-dropdown-icon.svg";
import { ReactComponent as CloseIcon} from "../../icons/close-red-icon.svg";
import {createMeeting} from "../../views/AsiaMeet/actions";
import translations from "../LanguageModal/translations";
import {getLanguage} from "../LanguageModal/reducer";
import {toastr} from "react-redux-toastr";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import ModalDateSelection from '../../components/ModalDateSelection/ModalDateSelection';
import {Value} from "sass";


export default function FormMeeting(props) {
    const { company, token, handleClose, persons, stand, mixpanel} = props;
    let date = new Date();

    date = date.toISOString().split('T')[0];
    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const [statusButton, setStatusButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCorrectTime, setIsCorrectTime] = useState(false);
    const [isDateModalOpen, setIsDateModalOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        validate()
    }, [formData]);


    const handleChangePerson = (e, {value}) => {
        setFormData({
            ...formData,
            person: value,
        });
        // console.log(formData)

    }
    const handleChangePlace = (e, {value}) => {
        setFormData({
            ...formData,
            place: value,
        });
    }
    const handleChangeTime = (e) => {
        const {value} = e.target;
        const minTime = '9:00'
        const maxTime = '18:00'
        const convertToMinutes = (time) => {
            const [hours, minutes] = time.split(':').map(Number);
            return hours * 60 + minutes;
        };
        const userTimeInMinutes = convertToMinutes(value);
        const minTimeInMinutes = convertToMinutes(minTime);
        const maxTimeInMinutes = convertToMinutes(maxTime);

        if (userTimeInMinutes >= minTimeInMinutes && userTimeInMinutes <= maxTimeInMinutes) {
            setFormData({
                ...formData,
                time: value,
            });
            setIsCorrectTime(true);
        } else {
            setIsCorrectTime(false);
        }

    }

    const handleChangeDate = (date) => {
        setFormData({
            ...formData,
            date: date.split('.').reverse().join('-'),
        });
    }

    const handleChangeText = (e) => {
        const {value, name} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }


    const validate = () => {
        if ( formData.time && isCorrectTime && (formData.place === 0 ? formData.placeInput : formData.place) && formData.date && formData.description) {
            setStatusButton(true);
        } else {
            setStatusButton(false)
        }

    }
    const handleSubmit = () => {
        setIsLoading(true);
        dispatch(createMeeting(token, languageString, 'ASIAEXPO 2024', company.id, ( formData.person === 0 ? formData.person : formData.person.id), (formData.place === 0 ? formData.placeInput : formData.place), formData.date, formData.time, formData.description, true))
            .then(() => {
                toastr.success(t('successMeet'));
                setIsLoading(false);
                handleClose();
            })
            .catch(() => {
                setIsLoading(false);
                handleClose();
            })
        setFormData({
            person: '',
            time: '',
            place: '',
            date: '',
            description: '',
        });
        if (mixpanel) {
            mixpanel.track('Action. Create meet')
        }
    }

    return (
        <div className="meeting-view">
            <CloseIcon className="meeting-close" onClick={handleClose} />
            <h2 className={"meeting-title"}>{t('scheduleMeet')} <span className='meeting-title-span'>{languageString === "en" ? company.name_en : company.name}</span></h2>
            <Form className="meeting-form">
                <FormField className="meeting-formfield">
                    <label className="meeting-label">{t('personMeet')}</label>
                    <Dropdown
                        className="meeting-dropdown"
                        fluid
                        search
                        selection
                        options={[{text: t('anyEmployee'), value: 0}, ...persons]}
                        placeholder={t('placeholderFioText')}
                        icon={<ArrowIcon />}
                        name='person'
                        value={formData.person}
                        onChange={handleChangePerson}
                    />
                </FormField>
                <FormField className="meeting-formfield">
                    <label className="meeting-label">{t('dateMeet')}</label>
                    <input className='meeting-text' onClick={() => setIsDateModalOpen(true)} value={formData.date} placeholder={t("dateMeeting")}/>
                    <ModalDateSelection
                        open={isDateModalOpen}
                        handleClose={() => setIsDateModalOpen(false)}
                        handleOpen={() => setIsDateModalOpen(true)}
                        selectedDate={formData.date}
                        callback={(date) => handleChangeDate(date)}
                        setMinDate={new Date(2024, 9, 23)}
                        setMaxDate={new Date(2024, 9, 25)}
                        header={t('dateMeet')}
                    />
                </FormField>
                <FormField className="meeting-formfield">
                    <label className="meeting-label">{t('timeMeet')}</label>
                    <input className="meeting-text" type="time" onChange={handleChangeTime} value={formData.time} />
                    {!isCorrectTime && <span  className='edit-error'>{t('correctMeetTime')}</span>}
                </FormField>
                <FormField className="meeting-formfield" >
                    <label className="meeting-label">{t('placeMeet')}</label>
                    <Dropdown
                        className="meeting-dropdown"
                        fluid
                        search
                        selection
                        placeholder={t("placeholderPlaceText")}
                        icon={<ArrowIcon />}
                        onChange={handleChangePlace}
                        options={[
                            {text:  t('standMeet')+ ' ' + stand, value: t('standMeet')+ ' ' + stand},
                            {text: t('otherMeet'), value: 0},
                        ]}
                        value={formData.place}
                    />
                    {formData.place === 0 && <input style={{marginTop: '10px'}} maxLength={30} className='meeting-text' onChange={handleChangeText} placeholder={t("placeholderPlaceText")} name='placeInput' value={formData.placeInput}></input>}
                </FormField>
                <FormField className="meeting-formfield" >
                    <label className="meeting-label">{t('descriptionMeet')}</label>
                    <textarea className="meeting-text" maxLength={79} name='description' placeholder={t("placeholderDescriptionText")} onChange={handleChangeText} value={formData.description}></textarea>
                </FormField>
                <Button
                    className="meeting-btn"
                    type="submit"
                    content={t("btnMeet")}
                    disabled={!statusButton}
                    onClick={() => handleSubmit()}
                />
            </Form>
            <Dimmer active={isLoading} inverted>
                <Loader active={isLoading}>{t("createMeet")}...</Loader>
            </Dimmer>
        </div>
    );
}

