import React from 'react';
import { Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { getShippingMethod, getShippingType } from './ShippingCard/reducer';
import {
    ADDRESS_ADDINFO,
    ADDRESS_ADDRESS,
    ADDRESS_CITY,
    ADDRESS_COMPANY,
    ADDRESS_COMPANY_ADDR, ADDRESS_COMPANY_CHIEF_NAME, ADDRESS_COMPANY_CHIEF_POST,
    ADDRESS_COMPANY_INN,
    ADDRESS_COMPANY_JOB_TITLE, ADDRESS_COMPANY_KPP, ADDRESS_COMPANY_OGRN,
    ADDRESS_COMPANY_SCOPE,
    ADDRESS_COUNTRY,
    ADDRESS_EMAIL,
    ADDRESS_FIRSTNAME,
    ADDRESS_GEOLAT,
    ADDRESS_GEOLON,
    ADDRESS_LASTNAME,
    ADDRESS_PHONE,
    ADDRESS_PHONE_COUNTRY,
    ADDRESS_POSTCODE,
    ADDRESS_STATE,
} from './AddressCard/actions';
import { getAddressFields } from './AddressCard/reducer';
import { getPaymentMethod } from "../views/Checkout_2_StepPayment/reducer";
import {getCartItems, getTotalPrice, hasOnlyVirtuals} from "../views/Cart/reducer";
import { putOrder } from "../views/Orders/actions";
import {getLanguage} from "./LanguageModal/reducer";
import {getDiscount} from "./CouponCard/reducer";

export default function ConfirmOrderButton(props) {

    const { dispatch, token, userData, mixpanel, navigate, isLoggedIn } = props;
    const cart_items = useSelector((state) => getCartItems(state.cart));
    const address_fields = useSelector((state) => getAddressFields(state.address));
    const payment_option = useSelector((state) => getPaymentMethod(state.payment));
    const language = useSelector(getLanguage);
    const languageString = language[0] + language[1];
    const discount = useSelector((state) => getDiscount(state.discount));
    const coupon = (discount.data && discount.data.coupon ? discount.data.coupon : '');
    const billingData = isLoggedIn
            ? userData?.billing
            : {
                first_name: address_fields[ADDRESS_FIRSTNAME],
                last_name: address_fields[ADDRESS_LASTNAME],
                country: address_fields[ADDRESS_COUNTRY],
                state: address_fields[ADDRESS_STATE],
                phone: address_fields[ADDRESS_PHONE],
                phone_country: address_fields[ADDRESS_PHONE_COUNTRY],
                email: address_fields[ADDRESS_EMAIL] ?? '',

                is_juridic: !!address_fields[ADDRESS_COMPANY],
                company: address_fields[ADDRESS_COMPANY] ?? '',
                company_job_title: address_fields[ADDRESS_COMPANY_JOB_TITLE] ?? '',
                company_law_address: address_fields[ADDRESS_COMPANY_ADDR] ?? '',
                company_inn: address_fields[ADDRESS_COMPANY_INN] ?? '',
                company_kpp: address_fields[ADDRESS_COMPANY_KPP] ?? '',
                company_ogrn: address_fields[ADDRESS_COMPANY_OGRN] ?? '',
                company_chief_post: address_fields[ADDRESS_COMPANY_CHIEF_POST] ?? '',
                company_chief_name: address_fields[ADDRESS_COMPANY_CHIEF_NAME] ?? '',
            };

    const orderData = {

        coupon,

        lang: languageString,

        payment_option,

        create_account: 1,

        cart_items: JSON.stringify(cart_items),

        billing: JSON.stringify(billingData),
    };

    function handleClick() {

        dispatch(putOrder(token, orderData, navigate, props.pathToRoute, languageString));

        if (mixpanel)
            mixpanel.track('Checkout Place Order', {
                ...userData,
                payment_option,
                ...address_fields,
                cart_items
            });
    }

    return (
        <Button
            color="green"
            className={`${props.addClassName} button-confirm`}
            onClick={handleClick}
            disabled={props.disabled ?? false}
        >
            {props.buttonText}
        </Button>
    );
}
