import React, { useEffect } from "react";
import "./style.css";
import {
    Loader,
    Dimmer,
} from "semantic-ui-react";
import { useSelector } from "react-redux";
import { getNotifications, getNotificationsFetching } from "../Notifications/reducer";
import { useParams } from "react-router";
import { fetchNotifications, NOTIFICATIONS_PAGE_COUNT, updateNotification } from "../Notifications/actions";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import SadIcon from "../../icons/sad-icon.png";
import MeetingComponent from "../../components/MeetingComponent/MeetingComponent";
import CalendarIcon from '../../icons/calendar-icon.png';
import VCard from "../../components/VCard";

export default function NotifyPage(props) {
    const { dispatch, userData, token, navigate, mixpanel } = props;

    const notifications = useSelector((state) => getNotifications(state.notifications));
    const isFetching = useSelector((state) => getNotificationsFetching(state.notifications));

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const { notifId } = useParams();
    const notifToDisplay = notifications ? notifications.find((notif) => String(notif.id) === String(notifId)) : null;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const readNotifications = (page) => {
        const params = {
            page,
            per_page: NOTIFICATIONS_PAGE_COUNT,
            order: 'desc',
            orderby: 'id',
            lang: languageString,
        }
        console.log('read notifications from card', params);
        dispatch(fetchNotifications(params, languageString, token));
    }

    useEffect(() => {
        if (! notifToDisplay) {
            const params = {
                page: 1,
                id: notifId,
                per_page: NOTIFICATIONS_PAGE_COUNT,
                order: 'desc',
                orderby: 'id',
                lang: languageString,
            }
            dispatch(fetchNotifications(params, languageString, token));
        }
        if (mixpanel) {
            mixpanel.track('View. Notification page')
        }

    }, [notifId]);

    //console.log('notification Card', notifications, notifId, notifToDisplay);

    function NotifyCard(props) {
        const { notification } = props;


        // если нет блока данных, то выходим сразу
        /*if (!notification.post_action_data)
            return;*/

        return (
            <div className='notif-container-page'>
                <div className='notify-page-message-req'>
                    <span>
                        <b>{t('recieveDate')}</b>
                        {notification.delivered_time ? notification.delivered_time : notification.timestamp}
                    </span>
                    <span>
                        <b>{t('message')}</b>
                        {notification.message}
                    </span>
                </div>

                { notification.post_action === 'meeting' ?
                    <MeetingComponent
                            token={token}
                            icon={CalendarIcon}
                            cardId={notification.post_action_data_object.id}
                            cardDate={notification.post_action_data_object.on_date}
                            cardTime={notification.post_action_data_object.on_time}
                            cardActions={notification.post_action_data_object.actions}
                            cardStatus={{
                                ru: notification.post_action_data_object.meeting_status_name,
                                en: notification.post_action_data_object.meeting_status_name_en
                            }}
                            updateFunc={readNotifications}
                            content={
                                <div>
                                    <div
                                        className='asiameet-card-text'>{notification.post_action_data_object.name}</div>
                                    <div className="asiameet-card-text"><span
                                        style={{fontWeight: 'bold'}}>{t("placeholderPlaceText")}:</span> {notification.post_action_data_object.placement}
                                    </div>
                                    <div className="asiameet-card-text">
                                        <span style={{fontWeight: 'bold'}}>{t('company')}: </span>
                                        {languageString === "ru" ?
                                            notification.post_action_data_object.respondent_exponent_name
                                            :
                                            notification.post_action_data_object.respondent_exponent_name_en}</div>
                                    <div className="asiameet-card-text">
                                        <span style={{fontWeight: 'bold'}}>{t('initiator')}: </span>
                                        {languageString === "ru" ?
                                            notification.post_action_data_object.initiator_user_name
                                            :
                                            notification.post_action_data_object.initiator_user_name_en}
                                    </div>
                                    <div className="asiameet-card-text">
                                        <span style={{fontWeight: 'bold'}}>{t('responder')}: </span>
                                        {languageString === "ru"
                                            ? notification.post_action_data_object.respondent_user_name
                                            : notification.post_action_data_object.respondent_user_name_en}
                                    </div>
                                </div>
                            }
                    /> : '' }

                { notification.post_action === 'vcard' ?
                    <VCard
                        vcard={notification.post_action_data_object}
                    /> : '' }

            </div>
        )
    }

    return (
        <div>
            <Dimmer className="page-loader" active={isFetching} inverted>
                <Loader active>{t("loadInfo")}...</Loader>
            </Dimmer>
            {
                notifToDisplay
                ? <div className='notif-container'>
                    <NotifyCard
                        key={notifToDisplay.id}
                        notification={notifToDisplay}
                        showLoader
                        {...props}
                    />
                </div>
                : <div className='view-notification'>
                        <img src={SadIcon} alt={""}/>
                        <span className='text-notification'>{t("notNotifications")}</span>
                </div>
            }
        </div>
    );
}
