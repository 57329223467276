import { combineReducers } from 'redux';
import {
    REQUEST_EXPONENTS,
    RECEIVE_EXPONENTS,
    EXPONENTS_PAGE_COUNT,
    RECEIVE_SEGMENTS,
    REQUEST_SEGMENTS,
    SET_COUNTRY_FILTER,
    CLEAR_COUNTRY_FILTER,
    CLEAR_SEGMENT_FILTER,
    SET_SEGMENT_FILTER,
    SET_SEARCH,
    CLEAR_SEARCH,
    SET_CURRENT_SEGMENT,
    CLEAR_CURRENT_SEGMENT,
    SET_URL,
    CLEAR_URL,
    SET_TITLE,
} from './action';
import {mergeObjectArrays} from "../../../configureStore";


const initialState = {
    country: [],
    segment: [],
};

const initialStatePDF = {
    url: "",
    title: ""
};


const filter = (state = initialState, action) => {
    switch (action.type) {
        case SET_COUNTRY_FILTER:
            return {
                ...state,
                country: action.country,
            };
        case CLEAR_COUNTRY_FILTER:
            return {
                ...state,
                country: [],
            };
        case CLEAR_SEGMENT_FILTER:
            return {
                ...state,
                segment: [],
            };
        case SET_SEGMENT_FILTER:
            return {
                ...state,
                segment: action.segment,
            };
        default:
            return state;
    }
};

const pdf = (state = initialStatePDF, action) => {
    switch (action.type) {
        case SET_URL:
            return {
                ...state,
                url: action.url,
            };
        case SET_TITLE:
            return {
                ...state,
                title: action.title,
            };
        default:
            return state;
    }
};

const search = (state = "", action) => {
    switch (action.type) {
        case SET_SEARCH:
            return action.search
        case CLEAR_SEARCH:
            return "";
        default:
            return state;
    }
};

const currentSegment = (state = "", action) => {
    switch (action.type) {
        case SET_CURRENT_SEGMENT:
            return action.segment
        case CLEAR_CURRENT_SEGMENT:
            return "";
        default:
            return state;
    }
};

const urlPDF = (state = "", action) => {
    switch (action.type) {
        case SET_URL:
            return action.url
        case CLEAR_URL:
            return "";
        default:
            return state;
    }
};

const titlePDF = (state = "", action) => {
    switch (action.type) {
        case SET_URL:
            return action.url
        case CLEAR_URL:
            return "";
        default:
            return state;
    }
};

const items = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_EXPONENTS:
            if (action.page === 1)
                return action.exponents;
            else
                return mergeObjectArrays(action.exponents, state);
        default:
            return state;
    }
};

const segments = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_SEGMENTS:
            return action.segments;
        default:
            return state;
    }
};

const hasMore = (state = false, action = 0) => {
    switch (action.type) {
        case REQUEST_EXPONENTS:
            return true;
        case RECEIVE_EXPONENTS:
            return action.exponents.length >= EXPONENTS_PAGE_COUNT;
        default:
            return state;
    }
};

const isFetching = (state = false, action) => {
    switch (action.type) {
        case REQUEST_EXPONENTS:
        case REQUEST_SEGMENTS:
            return true;
        case RECEIVE_EXPONENTS:
        case RECEIVE_SEGMENTS:
            return false;
        default:
            return state;
    }
};



export const getExponents = (state) => state.items;
export const getSegments = (state) => state.segments;
export const getCurrentSegment = (state) => state.currentSegment;
export const getPDFExponent = (state) => state.pdf;
export const getExponentsFetching = (state) => state.isFetching;
export const getExponentsHasMore = (state) => state.hasMore;
export const getExponentFilter = (state) => state.filter;
export const getExponentSearch = (state) => state.search;


export default combineReducers({
    items,
    segments,
    isFetching,
    hasMore,
    filter,
    search,
    currentSegment,
    pdf
});
