import React, { useRef, useState } from 'react';
import './styles.css';
import {useSelector} from "react-redux";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";
export default function VCard(props) {
    const {
        vcard = '',
    } = props
    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];

    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const [photoSrc, setPhotoSrc] = useState('');
    const cardInfoRef = useRef();
    const nameRef = useRef();
    const orgRef = useRef();
    const titleRef = useRef();
    const telRef = useRef();
    const addressRef = useRef();
    const emailRef = useRef();

    function downloadToFile(content, filename, contentType) {
        const a = document.createElement('a');
        const file = new Blob([content], { type: contentType });

        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();

        URL.revokeObjectURL(a.href);
    }

    const makeVCardVersion = () => `VERSION:3.0`;
    const makeVCardInfo = (info) => `N:${info}`;
    const makeVCardName = (name) => `FN:${name}`;
    const makeVCardOrg = (org) => `ORG:${org}`;
    const makeVCardTitle = (title) => `TITLE:${title}`;
    const makeVCardPhoto = (img) => `PHOTO;TYPE=JPEG;ENCODING=b:${img}`;
    const makeVCardTel = (phone) => `TEL;TYPE=WORK,VOICE:${phone}`;
    const makeVCardAdr = (state, country) => `ADR:;;;;${state};;${country}`;
    const makeVCardEmail = (email) => `EMAIL:${email}`;
    const makeVCardTimeStamp = () => `REV:${new Date().toISOString()}`;

    function makeVCard(vcard) {
        if(vcard) {
            downloadToFile(vcard, 'vcard.vcf', 'text/vcard');
        }
    }

    //console.log('vcard', vcard);
    if (!vcard)
        return;

    return (
        <div className='vcard'>
            <div className='vcard-header'>
                <p className='vcard-header-text'>{t('contact')}</p>
            </div>
            <div className='vcard-text-container'>
                <p className='vcard-text'>
                    <span style={{fontWeight: 'bold'}}>{t('name')}: </span>
                    {typeof vcard.user.first_name === 'string' ? vcard.user.first_name : ''}
                </p>
                <p className='vcard-text'>
                    <span style={{fontWeight: 'bold'}}>{t('surname')}: </span>
                    {typeof vcard.user.last_name === 'string' ? vcard.user.last_name : ''}
                </p>
                <p className='vcard-text'>
                    <span style={{fontWeight: 'bold'}}>{t('org')}: </span>
                    {typeof vcard.user.company === 'string' ? vcard.user.company : '' }
                </p>
                <p className='vcard-text'>
                    <span style={{fontWeight: 'bold'}}>{t('jobTitle')}: </span>
                    {typeof vcard.user.company_job_title === 'string' ? vcard.user.company_job_title : '' }
                </p>
                <p className='vcard-text'>
                    <span style={{fontWeight: 'bold'}}>{t('phoneNumber')}: </span>
                    {typeof vcard.user.phone === 'string' ? vcard.user.phone : ''}
                </p>
                <p className='vcard-text'>
                    <span style={{fontWeight: 'bold'}}>{t('mail')}: </span>
                    {typeof vcard.user.email === 'string' ? vcard.user.email : ''}
                </p>
                <p className='vcard-text'>
                    <span style={{fontWeight: 'bold'}}>{t('country')}: </span>
                    {typeof vcard.user.country === 'string' ? vcard.user.country : ''}
                </p>
                <p className='vcard-text'>
                    <span style={{fontWeight: 'bold'}}>{t('region')}: </span>
                    {typeof vcard.user.state === 'string' ? vcard.user.state : ''}
                </p>
            </div>
            <button className='vcard-btn' onClick={() => makeVCard(vcard.vcard)}>{t('addContact')}</button>
        </div>
    )
}
