import React, {useEffect, useState} from "react";
import {Button, Icon} from "semantic-ui-react";
import "./style.css";
import SadIcon from "../../icons/sad-icon.png";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import {useSelector} from "react-redux";
import {fetchNotifications, NOTIFICATIONS_PAGE_COUNT, updateNotification} from "./actions";
import {getNotifications, getNotificationsFetching, getNotificationsHasMore} from "./reducer";
import InfiniteView from "../../components/InfiniteView";
import { ReactComponent as Arrow } from '../../icons/arrow-icon.svg';
import ModalPrompt from "../../components/ModalPrompt";

export default function Notifications(props) {
    const { dispatch, token, navigate, mixpanel } = props

    const notifications = useSelector((state) => getNotifications(state.notifications));
    const isFetching = useSelector((state) => getNotificationsFetching(state.notifications));
    const hasMore = useSelector((state) => getNotificationsHasMore(state.notifications));

    const [openNotifyPopup, setOpenNotifyPopup] = useState(null);

    console.log('notifications', notifications)

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const readNotifications = (page) => {
        const params = {
            page,
            per_page: NOTIFICATIONS_PAGE_COUNT,
            order: 'desc',
            orderby: 'id',
            lang: languageString,
        }
        dispatch(fetchNotifications(params, languageString, token));
    }

    useEffect(() => {
        readNotifications(1)

        if (mixpanel) {
            mixpanel.track('View. Notifications')
        }

    }, []);


    function NotificationCard(props) {
        const {notification} = props;

        const language = useSelector(getLanguage);
        const languageString = language[0]+language[1];
        const t = (key) => {
            const languageString = language[0]+language[1];
            const translation = translations[languageString];
            return translation ? translation[key] || key : key;
        };

        const handleOpenNotify = () => {
            navigate(`/notifications/${notification.id}`);
            const status = 'viewed'
            dispatch(updateNotification(token, notification.id, status, true, languageString))
        }

        const handleDeleteNotify = () => {
            setOpenNotifyPopup(null);
            const status = 'deleted'
            dispatch(updateNotification(token, notification.id, status, false, languageString));
            console.log(notification.id, 'delete');
        }

        return (
                notification.history !== false ? <div className='notif-button'>
                        <span className='delivered-time'>
                            {notification.delivered_time ? notification.delivered_time : notification.timestamp}
                        </span>

                <span className={notification.status === 'viewed' ? 'notif-text' : 'notif-text-noviewed'}>
                        {notification.subject}
                    </span>
                <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', margin:'10px 0'}}>
                    <Button className='delete-notify-button' onClick={() => {setOpenNotifyPopup(notification.id); console.log(notification.id)}}>
                        <Icon name="trash"/>
                        <span>{t('delete')}</span>
                    </Button>

                    <ModalPrompt
                        open={openNotifyPopup === notification.id}
                        headerText={t("delNotifyHeader")}
                        messageText={t("deleteNotifyText")}
                        handleNo={() => {setOpenNotifyPopup(null);}}
                        handleYes={() => {handleDeleteNotify()}}
                        handleClose={() => {setOpenNotifyPopup(null)}}
                    />

                    <Button className='notif-view' onClick={() => handleOpenNotify()}>
                        <span className='notif-text-span'>{languageString === 'ru' ? 'Подробнее' : 'More'}</span>
                        <Arrow className=''/>
                    </Button>
                </div>
                </div> : <></>
        );

    }

    const NotificationsList = (props) => {
        const {notifications} = props

        return (
            !navigator.onLine
                ? <div className='view-bookmarks'>
                    <img src={SadIcon} alt={""}/>
                    <span className='text-bookmark'>{t("noNotifications")}</span>
                </div>
                : <div>
                    { notifications.length
                        ? notifications.map((n) => (
                            <NotificationCard
                                key={n.id}
                                notification={n}
                                showLoader
                                {...props}
                            />
                            ))
                        :
                        <div className='view-bookmarks'>
                            <img src={SadIcon} alt={""}/>
                            <span className='text-bookmark'>{t("noNotifications")}</span>
                        </div>
                    }
                </div>
        );
    }

    const loadMore = () => readNotifications(Math.round(notifications.length / NOTIFICATIONS_PAGE_COUNT) + 1);


    return (
        <>
            <div className="notif-container">
                <InfiniteView
                    dataLength={notifications.length}
                    nextFetch={loadMore}
                    hasMore={hasMore}
                    dataList={(
                        <NotificationsList
                            notifications={notifications}
                            {...props}
                        />
                    )}
                    hideEndMessage={true}
                    mustAuth={false}
                    isLoading={isFetching}
                    loaderText={t("loadNotifications") + "..."}
                    zeroText={ !isFetching ? (
                        <div className='view-bookmarks'>
                            <img src={SadIcon} alt={""}/>
                            <span className='text-bookmark'>{t("noNotifications")}</span>
                        </div>
                    ) : ""}
                />

            </div>
        </>
    );
}
