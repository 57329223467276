import { combineReducers } from 'redux';
import {
  RECEIVE_TICKET_DATA, REQUEST_TICKET_DATA,
} from './actions';

const ticketData = (state = [], action = 0) => {
  switch (action.type) {
    case RECEIVE_TICKET_DATA:
      return action.data;
    default:
      return state;
  }
};

const isFetching = (state = false, action = {}) => {
  switch (action.type) {
    case REQUEST_TICKET_DATA:
      return true;
    case RECEIVE_TICKET_DATA:
      return false;
    default:
      return state;
  }
};

export const getTicketData = (state) => state.ticketData;
export const isTicketFetching = (state) => state.isFetching;

const ticket = combineReducers({
  ticketData,
  isFetching,
});

export default ticket;
