import React, {useEffect, useState} from 'react';
import './styles.css';
import {Dimmer, Loader} from "semantic-ui-react";


export default function PdfFile(props) {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const iframe = document.querySelector('.pdf-file');

        const handleLoad = () => {
            setLoading(false);
        };

        if (iframe) {
            iframe.addEventListener('load', handleLoad);
        }

        return () => {
            if (iframe) {
                iframe.removeEventListener('load', handleLoad);
            }
        };
    }, []);

    return (
        <div className='pdf-view'>
            <iframe
                className='pdf-file'
                onLoad={() => setLoading(false)}
                src="https://drive.google.com/file/d/1CEDG6AyaB3zPUEhqXQ8PB-5NpI4w6vd1/preview"
            ></iframe>
            <Dimmer inverted active={loading}>
                <Loader></Loader>
            </Dimmer>
        </div>
    );
}
