import React, {useEffect, useRef, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';

import {
    Card, Divider, Header, Icon, Modal, Loader, Button, Image, Container, Dimmer,
} from 'semantic-ui-react';
import {isSearchVisible} from "../../components/NavTopBar/reducer";
import {getAuthToken, getUserData} from "../../components/UserLogin/reducer";
import {getLocationData} from "../../components/GeoLocation/reducer";
import {closeSearch} from "../../components/NavTopBar/actions";
import Html5QrcodePlugin from "./Html5QrcodePlugin";
import {ReactComponent as CloseIcon} from "../../icons/close-white-icon.svg";

import GeoLocation from "../../components/GeoLocation/GeoLocation";
import {getTicketData, isTicketFetching} from "./reducer";
import {fetchTicketData, receiveTicketData} from "./actions";
import {Link} from "react-router-dom";

import './styles.css';
import {toastr} from "react-redux-toastr";

import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import {fetchUserData, updateUserData} from "../../components/UserLogin/actions";
import VCard from "../../components/VCard";
import {update} from "lodash/object";

export default function AccessChecker({token}) {

    const [open, setOpen] = useState(false);
    const [vcardState, setVcardState] = useState(null);
    const dispatch = useDispatch();
    const vcard = useSelector((state) => getTicketData(state.ticket));
    const [isFetching, setIsFetching] = useState(false);
    const vCardPattern = /^BEGIN:VCARD[\s\S]*END:VCARD$/m;
    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const scannerRef = useRef(null);

    function downloadToFile(content, filename, contentType) {
        const a = document.createElement('a');
        const file = new Blob([content], { type: contentType });

        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();

        URL.revokeObjectURL(a.href);
    }

    const onNewScanResult = (decodedText, decodedResult) => {
        if(decodedText)  {
            scannerRef.current.pause();
            setIsFetching(true);
            dispatch(fetchTicketData(token, {ticket: decodedText, lang: languageString}))
                .then(() => {
                    dispatch(fetchUserData(token));
                    setOpen(true);
                })
                .finally(() => {
                    scannerRef.current.resume();
                    setIsFetching(false);

                })
        }
    };
    const wrapperClassName = `html5-camera-${languageString}`;
    return (
        <div className={'scanner ' + wrapperClassName} style={{width: '70%'}} >
            <Card centered color="orange" className="scanner-card" style={{width: '100%'}}>
                <Header as='h3' color="red"  className="scanner-reader" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Icon name='photo' />
                    <Header.Content >
                        <p >{t("scanBadge")}</p>
                        <Header.Subheader style={{color: 'black'}}>{t("pointQR")}</Header.Subheader>
                    </Header.Content>
                </Header>

                <Html5QrcodePlugin
                    forwardRef = {scannerRef}
                    className="qrcode-plugin"
                    aspectRatio={4/3}
                    qrbox={250}
                    fps={25}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                    verbose={false}
                />
            </Card>
            <Modal className='scan-popup' open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} centered>
                    <CloseIcon className='scan-close' onClick={() => setOpen(false)}></CloseIcon>
                    <VCard
                        vcard={vcard.vcard}
                    />
            </Modal>
            <Dimmer active={isFetching} inverted>
                <Loader>{languageString === 'ru' ? 'Загрузка...' : 'Loading...'}</Loader>
            </Dimmer>
        </div>
    );
}

