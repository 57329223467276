import React, {useEffect} from 'react';
import {Button, Card, Input} from 'semantic-ui-react';
import 'react-dadata/dist/react-dadata.css';
import {useSelector} from 'react-redux';

import './styles.css';
import { getCart } from '../../views/Cart/reducer';
import { getDiscount, getDiscountFetching } from './reducer';
import {fetchApplyCoupon} from "./actions";
import {fetchOrders} from "../../views/Orders/actions";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";

export default function CouponCard(props)  {

    const {dispatch} = props;

    const cart = useSelector((state) => getCart(state.cart));
    const isFetching = useSelector((state) => getDiscountFetching(state.discount));
    const coupon = useSelector((state) => getDiscount(state.discount));

    const [state, setState] = React.useState({
        error: false,
        coupon: '',
    });

    const language = useSelector(getLanguage);
    const languageString = language[0] + language[1];

    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] : key;
    };

    useEffect(() => {
        setState({error: !!coupon.message, coupon: coupon.data && coupon.data.coupon ? coupon.data.coupon : ''})
    }, [coupon]);

    const setField = (e) => setState({...state, coupon: e.target.value});

    const applyCoupon = () => {
        //if (state.coupon.length > 0 )
        // console.log('applyCoupon', state, cart);
        dispatch(fetchApplyCoupon(state.coupon, JSON.stringify(cart)));
    }

    // console.log('промокод', coupon, cart, isFetching, state);

    return (
      <Card centered className="coupon-card">
        <Card.Content className="coupon-card-content">
          <Input
            name="inputCoupon"
            placeholder={t('PromoCode')}
            fluid
            className="coupon-apply-input"
            value={state.coupon}
            onChange={ setField }
            loading={ isFetching }
          />
            <Button className="coupon-card-button-confirm" content={t('apply')} onClick={ applyCoupon } />
        </Card.Content>
      </Card>
    );
}

