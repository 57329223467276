import { combineReducers } from 'redux';
import {REQUEST_FORUMS, RECEIVE_FORUMS, FORUMS_PAGE_COUNT} from './actions';
import {mergeObjectArrays} from "../../../configureStore";

const items = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_FORUMS:
            if (action.page === 1)
                return action.forums;
            else
                return mergeObjectArrays(action.forums, state);
        default:
            return state;
    }
};

const hasMore = (state = false, action = 0) => {
    switch (action.type) {
        case REQUEST_FORUMS:
            return true;
        case RECEIVE_FORUMS:
            return action.forums.length >= FORUMS_PAGE_COUNT;
        default:
            return state;
    }
};

const isFetching = (state = false, action) => {
    switch (action.type) {
        case REQUEST_FORUMS:
            return true;
        case RECEIVE_FORUMS:
            return false;
        default:
            return state;
    }
};

export const getForums = (state) => state.items;
export const getForumsFetching = (state) => state.isFetching;
export const getForumsHasMore = (state) => state.hasMore;
export const getForumsPage = (state) => state.page;

export default combineReducers({
    items,
    isFetching,
    hasMore,
});
