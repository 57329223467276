import React, { useState, useEffect } from 'react';
import { Button, Modal, Dropdown } from 'semantic-ui-react';
import {ReactComponent as CloseIcon} from "../../icons/close-red-icon.svg";
import './languageModal.css'
import {useSelector} from "react-redux";
import {getLanguage} from "./reducer";
import translations from "./translations";

const languages = [
    { key: 'ru', value: 'ru', text: 'Русский', flag: 'ru' },
    { key: 'en', value: 'en', text: 'English', flag: 'us' },
];

const LanguageModal = ({ triggerButton, onLanguageChange, currentLanguage }) => {
    const [open, setOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    useEffect(() => {
        if (open) {
            setSelectedLanguage(currentLanguage); // Устанавливаем выбранный язык при открытии модального окна
        }
    }, [open, currentLanguage]);

    const handleLanguageChange = (e, { value }) => {
        setSelectedLanguage(value);
    };

    const handleSave = () => {
        setOpen(false);
        onLanguageChange(selectedLanguage);  // Вызываем функцию обратного вызова, передавая выбранный язык
    };

    return (
        <Modal
            open={open}
            trigger={React.cloneElement(triggerButton, { onClick: () => setOpen(true) })}
            onClose={() => setOpen(false)}
            size='tiny'
            id='language-modal'
        >
            <Modal.Content className='language-modal-content'>
                <CloseIcon onClick={() => setOpen(false)} className='close-icon-modal-language' />
                <p id="language-modal-title-text">{t("selectLang")}</p>
                <Dropdown
                    placeholder={t("lang")}
                    fluid
                    selection
                    options={languages}
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    id='language-modal-dropdown'
                />
            </Modal.Content>
            <Button fluid id='language-modal-button' onClick={handleSave}>
                {t("save")}
            </Button>
        </Modal>
    );
};

export default LanguageModal;
