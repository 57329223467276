
import { toastr } from 'react-redux-toastr';
import config, {AUTH_INVALID_MESSAGE, AUTH_INVALID_TOKEN, SERVICE_LOGICAL_ACCESS_DENIED} from "../../config/config";
import {dropToken, fetchUserData, receiveUserData, requestUserData} from "../../components/UserLogin/actions";
import {isOnline} from "../../components/WindowDimensions";

export const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS';
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
export const NOTIFICATIONS_PAGE_COUNT = 20;


export const requestNotifications = () => ({
    type: REQUEST_NOTIFICATIONS,
});

export const receiveNotifications = (notifications, page = 1) => ({
    type: RECEIVE_NOTIFICATIONS,
    notifications,
    page,
});


const process_api_error = (json, dispatch) => {
    dispatch(receiveNotifications([]));
    if (json.code === AUTH_INVALID_TOKEN) {
        dispatch(dropToken());
        toastr.error(AUTH_INVALID_MESSAGE);
    } else
    if (json.code === SERVICE_LOGICAL_ACCESS_DENIED)
        toastr.error(json.message);
    else
        toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const fetchNotifications = (params = {}, lang, token) => (dispatch) => {
    if (!isOnline(true, lang)) {

    } else {

        dispatch(requestNotifications(params.page ?? 1));
        const headers = token ? { Authorization: 'Bearer ' + token} : {};
        let url = config.API_NOTIFICATIONS_URL;
        if (params) {
            url += '?'
                + Object.keys(params)
                    .map((k) => k + '=' + encodeURIComponent(params[k]))
                    .join('&');
        }
        return fetch(url, {headers})
            .then((response) => response.json())
            .then((json) => {
                if (json.code === 200) {
                    dispatch(receiveNotifications(json.data, params.page ?? 1));
                } else {
                    dispatch(receiveNotifications([]));
                    process_api_error(json, dispatch, lang);
                }
            })
            .catch((error) => {
                toastr.error(lang === 'ru' ? 'Ошибка запроса' : 'Request error', String(error));
                dispatch(receiveNotifications([]));
            });
    }
};


export const updateNotification = (token, user_id, status, silent = false, lang) => (dispatch) => {
    if (!isOnline(true, lang)) {

    } else {
        dispatch(requestNotifications(1));

        const headers =  token ? { Authorization: 'Bearer ' + token } : {};
        let url = config.API_UPDATE_NOTIFICATION_URL + user_id;

        const formData = new FormData();
        formData.append('status', status);

        return fetch(url, { method: 'post', headers, body: formData, })
            .then((response) => response.json())
            .then((json) => {
                // console.log('updateNotif responce', json);
                if (json.code === 200) {
                    dispatch(receiveNotifications(json.data, 2));
                    dispatch(fetchUserData(token));
                    if (!silent)
                        toastr.success(lang === 'ru' ? 'Уведомление успешно удалено' : 'Notification successfully deleted');
                } else {
                    process_api_error(json, dispatch);
                }
            })
            .catch((ex) => {
                if (!isOnline(true, lang)) {

                } else {
                    toastr.error(lang === 'ru' ? 'Ошибка: ' + String(ex) : 'Error: ' + String(ex))
                }
            });
    }
};