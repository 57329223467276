import React, { useEffect, useState } from "react";
import "./style.css";
import {
    Rating,
    Accordion,
    Button,
    Modal,
    Divider,
    Form,
    FormField,
    Loader,
    Dimmer,
} from "semantic-ui-react";
import { ReactComponent as CloseIcon } from "../../../icons/close-red-icon.svg";
import { useSelector } from "react-redux";
import { getForums, getForumsFetching } from "../BusinessProgram/reducer";
import { useParams } from "react-router";
import { fetchForums, FORUMS_PAGE_COUNT, updateForum } from "../BusinessProgram/actions";
import { isNull } from "lodash";
import {toastr} from "react-redux-toastr";
import {updateUserData} from "../../../components/UserLogin/actions";
import {getLanguage} from "../../../components/LanguageModal/reducer";
import translations from "../../../components/LanguageModal/translations";
import SadIcon from "../../../icons/sad-icon.png";
import {isSafari} from "react-device-detect";

export default function EventBP(props) {
    const { dispatch, userData, token, navigate, mixpanel } = props;

    const [openPopup, setOpenPopup] = useState(false);
    const [openPopupQuestion, setOpenPopupQuestion] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [textQuestion, setTextQuestion] = useState();

    const forums = useSelector((state) => getForums(state.forums));
    const isFetching = useSelector((state) => getForumsFetching(state.forums));

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const { forumId } = useParams();
    let forumToDisplay = forums ? forums.find((forum) => forum.id === forumId) : null;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const params = {
            page: 1,
            per_page: FORUMS_PAGE_COUNT,
            order: "asc",
            orderby: "event_date, event_time_from",
            event: "ASIAEXPO 2024",
            id: forumId,
            user_id: userData.id,
            lang: languageString,
        };
        dispatch(fetchForums(params));

        if (mixpanel) {
            mixpanel.track('View. Business forum')
        }
    }, [forumId]);

    const handleClickClose = () => {
        setOpenPopup(false);
        setTextQuestion("");
    };

    const handleClickOpen = () => {
        setOpenPopup(true);
        setTextQuestion("");
    };

    const handleChange = (e) => {
        setTextQuestion(e.target.value);
    };

    const handleSendQuestion = () => {
        if (textQuestion) {
            if (!token)
                toastr.warning(t("toSendQuestion"));
            else {
                const query = JSON.stringify({
                    question: textQuestion,
                });
                dispatch(updateForum(props.token, forumId, query, true));
                handleClickClose();
            }
        }
        if (!textQuestion) {
            toastr.warning(t("validTextQuestion"))
        }
        if (mixpanel) {
            mixpanel.track('Action. Send question')
        }
    };

    const handleQuestionClick = (question) => {
        setSelectedQuestion(question);
        setOpenPopupQuestion(true);
    };

    const handleRate = (e, { rating }) => {
        const query = JSON.stringify({
            rating: rating,
        })
        dispatch(updateForum(props.token, forumId, query, true));
        if (mixpanel) {
            mixpanel.track('Action. Rate the forum')
        }
    };



    function ForumCard(props) {
        const { forum } = props;

        const eventDate =
            forum.event_date.split("-")[2] + "." +
            forum.event_date.split("-")[1] + "." +
            forum.event_date.split("-")[0];

        // forum.questions.map(function (question, index) {
        //     return console.log("question, ", question.timestamp);
        // });

        const panels = (forum.users || []).map((speaker, index) => ({
            key: `speaker-${index}`,
            title: {
                content: <>{languageString === "en" ? speaker.first_name_en : speaker.first_name} {languageString === "en" ? speaker.last_name_en : speaker.last_name}</>,
                icon: "dropdown",
            },
            content: {
                content: (
                    <div className="container-user-icon">
                        <img
                            className="photo-for-speaker"
                            src={speaker.photo}
                            alt={`${languageString === "en" ? speaker.first_name_en : speaker.first_name} ${languageString === "en" ? speaker.last_name_en : speaker.last_name}`}
                        />
                        <div className="text-for-speaker">
                            <span className="text-about-speaker">{languageString === "en" ? speaker.company_en : speaker.company}</span>
                            <span
                                className="text-about-speaker"
                                dangerouslySetInnerHTML={{ __html: languageString === "en" ? speaker.speciality_en : speaker.speciality }}
                            ></span>
                        </div>
                    </div>
                ),
            },
        }));

        return (
            <>
                <div className="main-container-one">
                    <div className="text-date-event">
                        <span className="text-for-date">{forum.event_date}</span>
                        <span className="text-for-date">{forum.event_time_from} - {forum.event_time_to}</span>
                        <span className="text-for-date">{languageString === "en" ? forum.event_placement_name_en : forum.event_placement_name}</span>
                    </div>

                    <p className="text-about-event" dangerouslySetInnerHTML={{ __html: languageString === "en" ? forum.content_en.replace(/\n\r/g, '<br/>') : forum.content.replace(/\n\r/g, '<br/>') }}></p>

                    {/*<div className="container-buttons-one">*/}
                    {/*    <Modal*/}
                    {/*        open={openPopup}*/}
                    {/*        onClose={() => setOpenPopup(false)}*/}
                    {/*        trigger={*/}
                    {/*            <Button*/}
                    {/*                className="button-event"*/}
                    {/*                content={t("writeQuestion")}*/}
                    {/*                onClick={handleClickOpen}*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*        centered*/}
                    {/*    >*/}
                    {/*        <Modal.Content>*/}
                    {/*            <button*/}
                    {/*                className="question-popup-button"*/}
                    {/*                onClick={() => setOpenPopup(false)}*/}
                    {/*            >*/}
                    {/*                <CloseIcon />*/}
                    {/*            </button>*/}
                    {/*            <br />*/}
                    {/*            <Form>*/}
                    {/*                <FormField>*/}
                    {/*                    <input*/}
                    {/*                        className="question-write"*/}
                    {/*                        name="name"*/}
                    {/*                        placeholder={t("uQuestion")}*/}
                    {/*                        value={textQuestion}*/}
                    {/*                        onChange={handleChange}*/}
                    {/*                        autoFocus={true}*/}
                    {/*                        type="text"*/}
                    {/*                        autoComplete="off"*/}
                    {/*                    />*/}
                    {/*                </FormField>*/}
                    {/*                <Divider fitted />*/}
                    {/*                <br />*/}
                    {/*                <div className="container-send-question">*/}
                    {/*                    <Button*/}
                    {/*                        type="submit"*/}
                    {/*                        className="send-question"*/}
                    {/*                        onClick={handleSendQuestion}*/}
                    {/*                    >*/}
                    {/*                        {t("send")}*/}
                    {/*                    </Button>*/}
                    {/*                </div>*/}
                    {/*            </Form>*/}
                    {/*        </Modal.Content>*/}
                    {/*    </Modal>*/}
                    {/*</div>*/}

                    {navigator.onLine ? (<div className="rate-forum">
                        <span className="text-rate-forum">{t("rateForum")}</span>
                        <Rating
                            style={{ marginLeft: "10px" }}
                            size="huge"
                            maxRating={5}
                            clearable
                            disabled={!token}
                            onRate={handleRate}
                            defaultRating={token ? forum.rating.user_rating : 0}
                        />
                    </div>) : (
                        <div className='view-images'>
                            <img src={SadIcon} alt={""}/>
                            <span className='text-bookmark'>{languageString === 'ru'
                                ? 'Для оценки форума, пожалуйста, подключитесь к интернету'
                                : 'To rate the forum, please connect to the Internet'}</span>
                        </div>
                    ) }

                    <br />

                    <div className="speaker-list">
                        <span className="speakers">{t("speakers")}</span>
                        <Accordion className="accordion-speakers" exclusive={false} panels={panels} />
                    </div>

                    <br />

                    {/*<span className="text-question-online">{t("questOnline")}</span>*/}
                    {/*<br />*/}

                    {/*<div className="box-of-questions">*/}
                    {/*    {forum && forum.questions && forum.questions.length > 0 ? forum.questions.map((question, index) => (*/}
                    {/*        <div key={index} className="main-container-one">*/}
                    {/*            <div*/}
                    {/*                className="question-online-container"*/}
                    {/*                onClick={() => handleQuestionClick(question)}*/}
                    {/*            >*/}
                    {/*                <div className="box-question">*/}
                    {/*                    <div className="text-box-question">*/}
                    {/*                      <span className="name-speaker-text">{question.user_first_name} {question.user_last_name}</span>*/}
                    {/*                      <span className="current-date-text">{*/}
                    {/*                          question.time_stamp*/}
                    {/*                      }</span>*/}
                    {/*                        <p className="text-question">{question.question}</p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    )) : ""}*/}
                    {/*</div>*/}

                    {/*{selectedQuestion && (*/}
                    {/*    <Modal*/}
                    {/*        className=""*/}
                    {/*        open={openPopupQuestion}*/}
                    {/*        onClose={() => setOpenPopupQuestion(false)}*/}
                    {/*        centered*/}
                    {/*    >*/}
                    {/*        <Modal.Content>*/}
                    {/*            <div className='text-box-question'>*/}
                    {/*                <span className='name-speaker-text'>{selectedQuestion.user_first_name} {selectedQuestion.user_last_name}</span>*/}
                    {/*                <span className='current-date-text'>{*/}
                    {/*                    selectedQuestion.time_stamp.split("-")[0] + '.' +*/}
                    {/*                    selectedQuestion.time_stamp.split('-')[1] + '.' +*/}
                    {/*                    selectedQuestion.time_stamp.split('-')[2]*/}
                    {/*                }</span>*/}
                    {/*                <p className='text-question-popup popup-text'>{selectedQuestion.question}</p>*/}
                    {/*            </div>*/}
                    {/*        </Modal.Content>*/}
                    {/*    </Modal>*/}
                    {/*)}*/}
                </div>
            </>
        );
    }

    return (
        <div>
            <Dimmer className="page-loader" active={isFetching} inverted>
                <Loader active>{t("loadInfo")}...</Loader>
            </Dimmer>
            {forumToDisplay ? (
                <ForumCard
                    key={forumToDisplay.id}
                    forum={forumToDisplay}
                    showLoader
                    {...props}
                />
            ) : (
                <div className='view-bookmarks'>
                    <img src={SadIcon} alt={""}/>
                    <span className='text-bookmark'>{t("notForums")}</span>
                </div>)}
        </div>
    );
}
