import 'whatwg-fetch';
import { toastr } from 'react-redux-toastr';
import config, { AUTH_INVALID_TOKEN } from '../../config/config';
import { dropToken } from '../../components/UserLogin/actions';

export const REQUEST_TICKET_DATA = 'REQUEST_TICKET_DATA';
export const RECEIVE_TICKET_DATA = 'RECEIVE_TICKET_DATA';

export const requestTicketData = () => ({
  type: REQUEST_TICKET_DATA,
});

export const receiveTicketData = (data) => ({
  type: RECEIVE_TICKET_DATA,
  data,
});
const process_expo_api_error = (json, dispatch) => {
  if (json.code === AUTH_INVALID_TOKEN) {
    dispatch(dropToken());
  }
  dispatch(receiveTicketData([]));
  toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};
export const fetchTicketData = (token, params = {}) => (dispatch) => {
  dispatch(requestTicketData());

  const headers = { Authorization: 'Bearer ' + token };
  const url = config.API_SAVE_ORDER_QRCODE_URL + '?' +
      Object.keys(params)
        .map((k) => k + '=' + encodeURIComponent(params[k]))
        .join('&');

  return fetch(url, { headers })
    .then((response) => response.json())
    .then((json) => {

      //console.log('fetchTicketData', json, params)

      if (json.code === 200 || json.code === 400)
        dispatch(receiveTicketData(json));
      else
        process_expo_api_error(json, dispatch);
    })
    .catch( (ex) => {
      toastr.error('Ошибка запроса: ' + String(ex));
      dispatch(receiveTicketData([]));
    } );
};


