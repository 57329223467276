import React, {useEffect} from 'react';
import SadIcon from '../../icons/sad-icon.png';
import VkIcon from '../../icons/vk-icon.png';
import YoutubeIcon from '../../icons/yt-icon.png';
import TelegramIcon from '../../icons/telegram-icon.png';
import "./style.css"
import {useSelector} from "react-redux";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";

const TravelPlan = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    return(
        <div id={"container"}>
            <img src={SadIcon} alt={""}/>
            <span id={"text"}>{t("travelPlan")}</span>
            <span id={"text"}>{t("travelPlanLinks")}</span>
            <div id={"socialWebs"}>
                <a href='https://vk.com/asiaexpo_ru'>
                    <img src={VkIcon} alt={""}/>
                </a>
                <a href='https://t.me/asia_expo'>
                    <img src={TelegramIcon} alt={""}/>
                </a>
                <a href='https://www.youtube.com/@asiaexpo_ru'>
                    <img src={YoutubeIcon} alt={""}/>
                </a>
            </div>
        </div>
    )
}

export default TravelPlan;